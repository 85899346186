<template>
  <div class="login-cont">
    <div class="loginbox">
      <div class="logo"> 
        <img src="@/assets/logo.png" class="log" width="200px" height="90px">
      </div>
      <h1>EWIDENCJA</h1><h1> PRZEKAŹNIKÓW</h1>
      <form action="" method="POST">
        <div class="material-input" id="loginbox-login">
          <input type="text" name="login" v-model="username" class="material-input-field" @blur="handleBlur($event)" autocomplete="off">
          <label for="login" class="material-input-label">Login</label>
          <div class="material-input-border"></div>
          <div class="material-input-error-msg">
            To pole jest wymagane!
          </div>
        </div>
        <div class="material-input" id="loginbox-password">
          <input type="password" name="passwd" v-model="password" class="material-input-field" @blur="handleBlur($event)">
          <label for="passwd" class="material-input-label">Hasło</label>
          <div class="material-input-border"></div>
          <div class="material-input-error-msg">
            To pole jest wymagane!
          </div>
        </div>
        <button role="submit" @click.prevent="login()">
          <span v-if="loginInProgress">
            <i class="fas fa-circle-notch fa-spin"></i>
          </span>
          <span v-else>
            Zaloguj
          </span>
        </button>
        <div class="loginbox-loginerror">
            {{loginError}}
        </div>
      </form>
      <div class="stopka">&copy;2018-{{(new Date()).getFullYear()}} Jastrzebska Spółka Kolejowa Sp. z o.o.</div>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';

export default {
  data() {
    return {
      username: "",
      password: "",
      loginError: "",
      loginInProgress: false
    };
  },
  methods: {
    login() {
        let err = false;
        this.loginError = "";
        

        if(this.username == ""){
            document.getElementById('loginbox-login').classList.add('incorrect');
            err = true;
        }else{
            document.getElementById('loginbox-login').classList.remove('incorrect');
        }
        if(this.password == ""){
            document.getElementById('loginbox-password').classList.add('incorrect');
            err = true;
        }else{
            document.getElementById('loginbox-password').classList.remove('incorrect');
        }
        if(err) return;

        this.loginInProgress = true;

        Axios.post("/auth/login", {
            login: this.username,
            password: this.password,
            origin: 'x-web-c'
        }).then(res => {
            this.loginInProgress = false;
            if(res.data.status == "error"){
                this.loginError = res.data.msg;
            }else{
                this.$store.commit('setAuthData', res.data.data);
                this.$router.push("/");
            }
        })
        .catch(e => {
            console.error(e);
        });
    },
    handleBlur(ev) {
        if(ev.srcElement.value == ""){
            ev.srcElement.parentNode.classList.remove('wc');
        }else{
            ev.srcElement.parentNode.classList.add('wc');
        }
    }
  }
};
</script>
<style lang="scss" scoped>
.login-cont {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
}
h1 {
  margin: 0;
  text-align: center;
  font-size: 22px;
  color: #444;
  font-weight: 400;
  margin-bottom: 5px;
  line-height: 20px;
}
.stopka {
  color: #777;
  font-size: 13px;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: -25px;
  left: 0;
}
.loginbox {
  // width: 440px;
  // height: 450px;
  min-width: 400px;
  max-width: 500px;
  border: 1px solid #bbb;
  background-color: white;
  color: #ecf0f1;
  top: 100px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  box-sizing: border-box;
  padding: 50px 30px;
  font-family: "Raleway", sans-serif;
  border-radius: 3px;
  text-align: center;
  margin: 0px;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.3);

  .logo {
    max-width: 200px;
    margin: 0 auto;
    margin-bottom: 10px;

    img {
      width: 100%;
      height: auto;
    }
  }

  form {
    margin-top: 50px;
  }

  button {
    border: none;
    outline: none;
    width: 100%;
    padding: 10px 15px;
    background: #27ae60;
    color: white;
    font-size: 18px;
    border-radius: 3px;
    cursor: pointer;
    font-family: inherit;
    transition: all 0.2s ease;
    display: block;
    margin-top: 15px;

    &:hover {
      background-color: darken(#27ae60, 10%);
    }
  }

  .loginbox-loginerror{
      color: #e74c3c;
      margin-top: 5px;

  }
}
</style>

