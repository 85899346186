<template>
    <div class="main-view-container" style="position: relative;">
        <div class="main-view-heading" style="padding: 20px 15px; margin-bottom: 0;">
            <h1 style="display: flex; align-items: center; margin: 0;">
                <router-link tag="button" class="btn btn-secondary" :to="'/transmitters/' + $route.params.code + '/details'" style="margin-right: 10px;">
                    <i class="fas fa-arrow-left"></i> Powrót
                </router-link>
                Zmiana statusu przekaźnika
            </h1>
        </div>
        <div class="item-list-settings">
            <transition name="fade" mode="out-in">
                <jsk-spinner :transparent="true" :static="true" v-if="loading">Pobieranie danych</jsk-spinner>  
                <div class="item-list-settings-line" style="text-algin:center; padding:15px; margin:10px; display: flex; justify-content: flex-start; width: 100%;" v-else>
                    <p style="width: 100%; margin-bottom: 30px;">Wybierz rodzaj zmiany statusu:</p>
                    <router-link :to="'/transmitters/' + $route.params.code + '/change-status/stand/stand'" tag="div" class="rbtd" v-if="transmitterStatus == 'stojak'">
                        <button class="btn btn-primary">
                            stojak-stojak
                        </button>
                    </router-link>
                    <router-link :to="'/transmitters/' + $route.params.code + '/change-status/supply/stand'" tag="div" class="rbtd" v-if="transmitterStatus == 'stojak' || transmitterStatus == 'zapas'">
                        <button class="btn btn-primary">
                            zapas-stojak/stojak-zapas
                        </button>
                    </router-link>
                    <div class="rbtd" v-if="transmitterStatus == 'magazyn'">
                        <button class="btn btn-primary" @click="storeToOtp()">
                            magazyn-OTP
                        </button>
                    </div>
                    <router-link :to="'/transmitters/' + $route.params.code + '/change-status/otp/stock'" tag="div"  class="rbtd" v-if="transmitterStatus == 'otp'">
                        <button class="btn btn-primary">
                            OTP-magazyn
                        </button>
                    </router-link>
                    <router-link :to="'/transmitters/' + $route.params.code + '/change-status/stock/supply'" tag="div" class="rbtd" v-if="transmitterStatus == 'magazyn'">
                        <button class="btn btn-primary">
                            magazyn-zapas
                        </button>
                    </router-link>
                    <div class="rbtd" v-if="transmitterStatus == 'zapas'">
                        <button class="btn btn-primary" @click="supplyToStore()">
                            zapas-magazyn
                        </button>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import jskSpinner from '../components/Spinner.vue';

export default {
    data(){
        return{
            loading: false,

            transmitterStatus: ''
        }
    },
    methods: {
        ...mapActions([
            "GET",
            "POST"
        ]),
        supplyToStore(){
            this.POST({
                url: '/transmitters/changeStatus/from/zapas/to/magazyn',
                data: {
                    code: this.$route.params.code
                }
            })
            .then(res => {
                this.$store.commit('newSuccess', res.msg);
                this.$router.push('/transmitters/' + this.$route.params.code + '/details');
            })
            .catch(e => {
                console.error(e);
            });
        },
        storeToOtp(){
            this.POST({
                url: '/transmitters/changeStatus/from/magazyn/to/otp',
                data: {
                    code: this.$route.params.code
                }
            })
            .then(res => {
                this.$store.commit('newSuccess', res.msg);
                this.$router.push('/transmitters/' + this.$route.params.code + '/details');
            })
            .catch(e => {
                console.error(e);
            });
        }
    },
    created(){
        this.loading = true;
        this.GET({
            url: '/transmitters/' + this.$route.params.code
        })
        .then(res => {
            this.transmitterStatus = res.data.transmitter.status;
            this.loading = false;
        })
        .catch(err => {
            console.error(e);
        });
    },
    components: {
        jskSpinner
    }
}
</script>


<style lang="scss">
.rbtd{
    width: calc(33.33% - 20px);
    margin-right: 30px;
    margin-bottom: 30px;

    &:nth-child(3n + 1){
        margin-right: 0px;
    }

    button{
        width: 100%;
        height: 100%;
        padding: 30px 15px;
    }
}
</style>
