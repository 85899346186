<template>
    <div class="main-view-container" style="position: relative;">
        <div class="main-view-heading" style="padding: 20px 15px; margin-bottom: 0;">
            <h1 style="display: flex; align-items: center; margin: 0;">
                <router-link tag="button" class="btn btn-secondary" to="/transmitters" style="margin-right: 10px;">
                    <i class="fas fa-arrow-left"></i> Powrót
                </router-link>
                Dodawanie przekaźników
            </h1>
        </div>
        
        <div class="main-view-content-container" style="padding:15px;" v-if="!loading">
            <h2 style="padding: 20px 0 3px; margin-bottom: 40px; font-weight:400; width: 100%;">Tworzenie nowego obiektu przekaźnika w
            kolekcji <i>Przekaźniki</i></h2>

            <div class="col-1-3">
                <div class="nt-form-group">
                    <b>Dane:</b>
                </div>
            </div>
            <div class="col-2-3">
                <div class="nt-form-group">
                    <b>Opis pola:</b>
                </div>
            </div>

            <div class="col-1-3">
                <div class="fradio__container">
                    <button @click="stt('JRK')" :class="{'active': (cttname == 'JRK')}" class="fradio__button">
                        JRK
                    </button>
                    <button @click="stt('RK')" :class="{'active': (cttname == 'RK')}" class="fradio__button">
                        RK
                    </button>
                    <button @click="stt('JRB')" :class="{'active': (cttname == 'JRB')}" class="fradio__button">
                        JRB
                    </button>
                    <button @click="stt('JRJ')" :class="{'active': (cttname == 'JRJ')}" class="fradio__button">
                        JRJ
                    </button>
                </div>
                <div class="nt-form-group">
                    <!-- <label for="ttype">Typ przekaźnika</label> -->
                    <model-list-select
                        :list="transmitterTypes"
                        option-value="_id"
                        option-text="transmitterType"
                        v-model="transmitter.transmitterType"
                        placeholder="Typ przekaźnika"
                        @searchchange="searchTType"
                        :isError="tTypeError"
                    >
                    </model-list-select>
                </div>
            </div>
            <div class="col-2-3">
                <div class="nt-form-group m">
                    Pole obowiązkowe, po wpisaniu kawałka frazy zostanie wyświetlona lista typów przekaźników pasujących do wpisanego słowa.
                </div>
            </div>

            <div class="col-1-3">
                <div class="nt-form-group">
                    <div class="material-input" ref="newVariant">
                        <input type="text" name="login" v-model="transmitter.variant" class="material-input-field" @blur="handleBlur($event)" autocomplete="off">
                        <label for="login" class="material-input-label">Odmiana przekaźnika</label>
                        <div class="material-input-border"></div>
                        <div class="material-input-error-msg">
                            To pole nie może być puste, musi zawierać liczbę, maksymalnie 5-cyfrową
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2-3">
                <div class="nt-form-group m">
                    Pole obowiązkowe, typ zawartości: liczba maksymalnie 5-cyfrowa
                </div>
            </div>

            <div class="col-1-3">
                <div class="nt-form-group">
                    <div class="material-input" ref="newSerialNumber">
                        <input type="text" name="login" v-model="transmitter.serialNumber" class="material-input-field" @blur="handleBlur($event)" autocomplete="off">
                        <label for="login" class="material-input-label">Numer seryjny</label>
                        <div class="material-input-border"></div>
                        <div class="material-input-error-msg">
                            To pole nie może być puste, zawartość jak w opisanym obok formacie
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2-3" >
                <div class="nt-form-group m">
                    Pole obowiązkowe, typ zawartości: tekstowo-liczbowa
                </div>
            </div>

            <div class="col-1-3">
                <div class="nt-form-group">
                    <div class="material-input" ref="newProductionDate">
                        <input type="text" name="login" v-model="transmitter.productionDate" class="material-input-field" @blur="handleBlur($event)" autocomplete="off">
                        <label for="login" class="material-input-label">Data produkcji</label>
                        <div class="material-input-border"></div>
                        <div class="material-input-error-msg">
                            To pole nie może być puste, format zawartości RRRR-MM-DD
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2-3" >
                <div class="nt-form-group m">
                    Pole obowiązkowe, typ zawartości: data w formacie RRRR-MM-DD, nie można podać przyszłej daty
                </div>
            </div>

            <div class="col-1-3">
                <div class="mt-form-group">
                    <div class="material-input" ref="newTransmitterCode">
                        <input type="text" name="login" v-model="transmitter.transmitterCode" class="material-input-field" @blur="handleBlur($event)" autocomplete="off">
                        <label for="login" class="material-input-label">Kod przekaźnika</label>
                        <div class="material-input-border"></div>
                        <div class="material-input-error-msg">
                            To pole nie może być puste, musi zawierać unikalny kod przekaźnika
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2-3" >
                <div class="nt-form-group m">
                    Pole obowiązkowe, typ zawartości: liczbowa [8 cyfr]
                </div>
            </div>

            <div class="col-1-3">
                <div class="nt-form-group">
                    <div class="material-input" ref="newOtpCode">
                        <input type="text" name="login" v-model="transmitter.otpCode" class="material-input-field" @blur="handleBlur($event)" autocomplete="off">
                        <label for="login" class="material-input-label">Kod OTP przekaźnika</label>
                        <div class="material-input-border"></div>
                        <div class="material-input-error-msg">
                            To pole nie może być puste, musi zawierać unikalny kod OTP
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2-3" >
                <div class="nt-form-group m">
                    Pole obowiązkowe, typ zawartości: liczbowa [8 cyfr]
                </div>
            </div>

            <div class="col-1-3">
                <div class="nt-form-group">
                    <div class="material-input" ref="newOtpDate">
                        <input type="text" name="login" v-model="transmitter.otpDate" class="material-input-field" @blur="handleBlur($event)" autocomplete="off">
                        <label for="login" class="material-input-label">Data ostatniego OTP</label>
                        <div class="material-input-border"></div>
                        <div class="material-input-error-msg">
                            To pole nie może być puste, data w formacie RRRR-MM-DD
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2-3" >
                <div class="nt-form-group m">
                    Pole obowiązkowe, typ zawartości: data w formacie RRRR-MM-DD, nie można podać przyszłej daty ani daty wcześniejszej niż data produkcji
                </div>
            </div>

            <div class="col-1-3">
                <div class="nt-form-group">
                    <div class="material-input" ref="newOtpResurs">
                        <input type="text" name="login" v-model="transmitter.otpResurs" class="material-input-field" @blur="handleBlur($event)" autocomplete="off">
                        <label for="login" class="material-input-label">Liczba lat pomiędzy OTP</label>
                        <div class="material-input-border"></div>
                        <div class="material-input-error-msg">
                            To pole nie może być puste, liczba z przedziału 1-30
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2-3" >
                <div class="nt-form-group m">
                    Pole obowiązkowe, typ zawartości: liczba z zakresu 1-30
                </div>
            </div>

            <div class="col-1-3">
                <div class="nt-form-group">
                    <p style="margin-top: 0">Status przekaźnika</p>
                    <div class="fradio__container">
                        <button @click="transmitter.status = 'stojak'" :class="{'active': (transmitter.status == 'stojak')}" class="fradio__button">
                            Stojak
                        </button>
                        <button @click="transmitter.status = 'zapas'" :class="{'active': (transmitter.status == 'zapas')}" class="fradio__button">
                            Zapas
                        </button>
                        <button @click="transmitter.status = 'otp'" :class="{'active': (transmitter.status == 'otp')}" class="fradio__button">
                            OTP
                        </button>
                        <button @click="transmitter.status = 'magazyn'" :class="{'active': (transmitter.status == 'magazyn')}" class="fradio__button">
                            Magazyn
                        </button>
                    </div>
                    <p style="margin-bottom: 0; color: #e74c3c" v-if="statusError"> 
                        Nie wybrano statusu przekaźnika
                    </p>
                    <!-- <model-list-select
                        :list="[{value: 'stojak', text: 'Stojak'}, {value: 'zapas', text: 'Zapas'}, {value: 'otp', text: 'OTP'}, {value: 'magazyn', text: 'Magazyn'}]"
                        option-value="value"
                        option-text="text"
                        v-model="transmitter.status"
                        placeholder="Status przekaźnika"
                        :isError="statusError"
                    >
                    </model-list-select> -->
                </div>
            </div>
            <div class="col-2-3">
                <div class="nt-form-group m">
                    Pole obowiązkowe, zawartość wybrana z listy rozwijanej
                </div>
            </div>

            <transition name="fade">
            <div class="col-1-3" v-if="transmitter.status == 'stojak'">
                <div class="nt-form-group">
                    <div class="material-input" ref="newStand">
                        <input type="text" name="login" v-model="transmitter.stand" class="material-input-field" @blur="handleBlur($event)" autocomplete="off">
                        <label for="login" class="material-input-label">Numer stojaka</label>
                        <div class="material-input-border"></div>
                        <div class="material-input-error-msg">
                            Pole obowiązkowe dla statusu 'Stojak'
                        </div>
                    </div>
                </div>
            </div>
            </transition>
            <transition name="fade">
            <div class="col-2-3" v-if="transmitter.status == 'stojak'">
                <div class="nt-form-group m">
                    Pole obowiązkowe dla statusu 'Stojak', liczba maksymalnie 4-cyfrowa
                </div>
            </div>
            </transition>

            <transition name="fade">
            <div class="col-1-3" v-if="transmitter.status == 'stojak' || transmitter.status == 'zapas'">
                <div class="nt-form-group">
                    <model-list-select
                        :list="locations"
                        option-value="_id"
                        option-text="district"
                        v-model="transmitter.location"
                        placeholder="Lokalizacja przekaźnika"
                        @searchchange="searchLocations"
                        :isError="locationError"
                    >
                    </model-list-select>
                </div>
            </div>
            </transition>
            <transition name="fade">
            <div class="col-2-3" v-if="transmitter.status == 'stojak' || transmitter.status == 'zapas'">
                <div class="nt-form-group m">
                    Pole obowiązkowe dla statusów 'Stojak' i 'Zapas', lista wyboru, dostępne lokalizacje zostaną wczytane po wpisaniu fragmentu frazy.
                </div>
            </div>
            </transition>



            <div class="col-1-3">
                <button class="btn btn-primary" @click="addTransmitter()" style="width: 100%; text-align: center;">
                    <i class="fas" :class="{'fa-circle-notch fa-spin': pendingAdding, 'fa-check-circle': !pendingAdding}"></i> Utwórz przekaźnik
                </button>
            </div>
            <div class="col-2-3">
                {{msg}}
            </div>
            
        </div>
        <div v-else style="height: 300px; background-color: #f5f5f5; border-radius: 5px;"></div>
    </div>
</template>

<script>
import {ModelListSelect} from 'vue-search-select';
import {mapActions} from 'vuex';

export default {
    data(){
        return{
            transmitter: {
                transmitterType: '',
                variant: '',
                serialNumber: '',
                transmitterCode: '',
                productionDate: '',
                otpCode: '',
                otpDate: '',
                otpResurs: '',
                status: '',
                stand: '',
                location: ''
            },

            tTypeError: false,
            locationError: false,
            statusError: false,

            transmitterTypes: [],
            locations: [],

            pendingAdding: false,
            msg: '',
            loading: false
        }
    },
    computed: {
        cttname(){
            let ind = this.transmitterTypes.findIndex(item => {
                return item._id == this.transmitter.transmitterType;
            });

            if(ind != -1){
                return this.transmitterTypes[ind].transmitterType;
            }
            return '';
        }
    },
    methods: {
        ...mapActions([
            "GET",
            "POST"
        ]),
        stt(str){
            this.GET({
                url: "/transmitters-types?s=" + str
            })
            .then(res => {
                this.transmitterTypes = res.data.transmittersTypes;

                let ind = this.transmitterTypes.findIndex(item => {
                    return item.transmitterType == str;
                });

                if(ind != -1){
                    this.transmitter.transmitterType = this.transmitterTypes[ind]._id;
                }
            })
            .catch(e => {
                console.error(e);
            });
        },
        searchTType(s){
            this.GET({
                url: "/transmitters-types?s=" + s
            })
            .then(res => {
                this.transmitterTypes = res.data.transmittersTypes;
            })
            .catch(e => {
                console.error(e);
            })
        },
        searchLocations(s){
            this.GET({
                url: "/locations?s=" + s
            })
            .then(res => {
                this.locations = res.data.locations;
            })
            .catch(e => {
                console.error(e);
            })
        },
        handleBlur(ev) {
            if(ev.srcElement.value == ""){
                ev.srcElement.parentNode.classList.remove('wc');
            }else{
                ev.srcElement.parentNode.classList.add('wc');
            }
        },
        addTransmitter(){
            this.tTypeError = false;
            this.locationError = false;
            this.statusError = false;
            this.$refs.newVariant.classList.remove('incorrect');
            this.$refs.newSerialNumber.classList.remove('incorrect');
            this.$refs.newTransmitterCode.classList.remove('incorrect');
            this.$refs.newProductionDate.classList.remove('incorrect');
            this.$refs.newOtpCode.classList.remove('incorrect');
            this.$refs.newOtpDate.classList.remove('incorrect');
            this.$refs.newOtpResurs.classList.remove('incorrect');
            //this.$refs.newStand.classList.remove('incorrect');

            let err = false;

            let prodDatePassed = false;

            if(this.transmitter.transmitterType == ""){
                this.tTypeError = true;
                err = true;
            }
            if(this.transmitter.status == ""){
                this.statusError = true;
                err = true;
            }
            if(this.transmitter.location == "" && (this.transmitter.status == 'stojak' || this.transmitter.status == 'zapas')){
                this.locationError = true;
                err = true;
            }
            if(this.transmitter.variant == "" || isNaN(parseInt(this.transmitter.variant)) || parseInt(this.transmitter.variant) > 99999){
                this.$refs.newVariant.classList.add('incorrect');
                err = true;
            }
            //const serialNumberTest = new RegExp(/^\d{1,5}\/\d{1,2}$/g);
            if(this.transmitter.serialNumber == ""/* || !serialNumberTest.test(this.transmitter.serialNumber)*/){
                this.$refs.newSerialNumber.classList.add('incorrect');
                err = true;
            }
            if(this.transmitter.transmitterCode == "" || isNaN(this.transmitter.transmitterCode) || this.transmitter.transmitterCode.split("").length != 8){
                this.$refs.newTransmitterCode.classList.add('incorrect');
                err = true;
            }
            const dateTest = new RegExp(/^\d{4}-\d{2}-\d{2}$/g);
            if(this.transmitter.productionDate == "" || !dateTest.test(this.transmitter.productionDate)){
                this.$refs.newProductionDate.classList.add('incorrect');
                err = true;
            }else{

                prodDatePassed = true;
                const prodDate = new Date(this.transmitter.productionDate);

                if(prodDate.getTime() > Date.now()){
                    this.$refs.newProductionDate.classList.add('incorrect');
                    err = true; 
                }
            }
            if(this.transmitter.otpCode == "" || isNaN(this.transmitter.otpCode) || this.transmitter.otpCode.split("").length != 8){
                this.$refs.newOtpCode.classList.add('incorrect');
                err = true;
            }
            const dateTest2 = new RegExp(/^\d{4}-\d{2}-\d{2}$/g);
            if(this.transmitter.otpDate == "" || !dateTest2.test(this.transmitter.otpDate)){
                this.$refs.newOtpDate.classList.add('incorrect');
                err = true;
            }else{
                const otpDate = new Date(this.transmitter.otpDate);

                if(otpDate.getTime() > Date.now()){
                    this.$refs.newOtpDate.classList.add('incorrect');
                    err = true; 
                }else if(prodDatePassed){
                    
                    const prodDate = new Date(this.transmitter.productionDate);

                    if(prodDate.getTime() > otpDate.getTime()){
                        this.$refs.newOtpDate.classList.add('incorrect');
                        err = true; 
                    }
                }
            }
            if(this.transmitter.otpResurs == "" || isNaN(parseInt(this.transmitter.otpResurs)) || parseInt(this.transmitter.otpResurs) < 1 || parseInt(this.transmitter.otpResurs) > 30){
                this.$refs.newOtpResurs.classList.add('incorrect');
                err = true;
            }
            if(this.transmitter.transmitterCode == this.transmitter.otpCode){
                this.$refs.newTransmitterCode.classList.add('incorrect');
                this.$refs.newOtpCode.classList.add('incorrect');
                err = true;
            }
            if((this.$refs.newStand && this.transmitter.stand == "" && this.transmitter.status == 'stojak') || (this.$refs.newStand && isNaN(parseInt(this.transmitter.stand)) || parseInt(this.transmitter.stand) > 9999)){
                this.$refs.newStand.classList.add('incorrect');
                err = true;
            }

            if(err){
                this.$store.commit('newError', 'W formularzu pojawiły się błędy. Proszę przejrzeć wyświetlone pod polami komunikaty.');
                return false;
            }

            let date1 = this.transmitter.productionDate.split("-");
            if(date1[1] > 12) date1[1] = 12;
            if(date1[2] > 31) date1[2] = 28;
            this.transmitter.productionDate = date1.join("-");

            let date2 = this.transmitter.otpDate.split("-");
            if(date2[1] > 12) date2[1] = 12;
            if(date2[2] > 31) date2[2] = 28;
            this.transmitter.otpDate = date2.join("-");

            this.pendingAdding = true;


            this.POST({
                url: "/transmitters",
                data: this.transmitter
            })
            .then(res => {
                this.pendingAdding = false;
                this.$store.commit('newSuccess', res.msg);
                this.$router.push("/transmitters");
            })
            .catch(err => {
                this.pendingAdding = false;
                this.msg = err;
                console.error(err);
            });

        }
    },
    components: {
        ModelListSelect
    },
    created(){
        this.GET({
            url: "/locations/default"
        })
        .then(res => {
            this.transmitter.location = res.data.location._id;
            this.locations = [res.data.location];
            this.loading = false;
        })
        .catch(err => {
            console.error(err);
        });
    }
}
</script>

<style lang="scss">
.nt-form-group{
    margin-bottom: 20px;

    label{
        display: block;
        margin-bottom: 4px;
    }
}
</style>
