<template>
    <div class="main-view-container" style="position: relative;">
        <div class="main-view-heading"  style="margin-bottom: 0;">
            <h1>{{(cDate.getHours() < 19)? "Dzień dobry!" : "Dobry wieczór!"}}</h1>
            <p style="float:right font-weight:400;">{{days[cDate.getDay()]}}, {{padToTwo(cDate.getDate())}}.{{padToTwo(cDate.getMonth() + 1)}}.{{cDate.getFullYear()}} {{padToTwo(cDate.getHours())}}:{{padToTwo(cDate.getMinutes())}}:{{padToTwo(cDate.getSeconds())}} </p>
        </div>
        <div style="border-top: 1px solid #ddd; padding:15px; display: flex; justify-content: space-between; flex-wrap: wrap;">
            <router-link to="/transmitters" tag="div" class="home-button">
                Przekaźniki
            </router-link>
            <router-link to="/transmitters-types" tag="div" class="home-button">
                Typy przekaźników
            </router-link>
            <router-link to="/locations" tag="div" class="home-button">
                Lokalizacja
            </router-link>
            <router-link to="/users" tag="div" class="home-button" v-if="$store.getters.getUser.permissions == 'admin'">
                Użytkownicy
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            cDate: new Date(),
            interval: null,

            days: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
        }
    },

    methods: {
        padToTwo(n){
            return (n < 10)? "0" + n.toString() : n.toString();
        }
    },

    created(){
        this.interval = setInterval(() => {
            this.cDate = new Date();
        }, 1000);
    },
    destroyed(){
        clearInterval(this.interval);
    }
}
</script>

<style lang="scss">
.home-button{
    width: 250px;
    height: 250px;
    background-color: #27AE60;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    box-shadow: 1px 1px 1px 0 rgba(0,0,0,.4);
    transition: all .2s ease;
    margin-bottom: 15px;

    &:before{
        content: "\f0a0";
        position: absolute;
        top: 5px;
        left: 10px;
        
        opacity: .2;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 100px;
        transition: all .2s ease;
    }

    &:nth-child(4):before{
        content: "\f14e";
    }
    &:nth-child(3):before{
        content: "\f46d";
    }
    &:nth-child(5):before{
        content: "\f0c0";
    }

    &:after{
        position: absolute;
        top: 0;
        left: -5%;
        width: 300%;
        height: 100%;
        transform: rotate(-45deg);
        content: '';
        background-color: #fff;
        opacity: .3;
        transition: all .2s ease;
    }

    &:hover:after{
        left: 5%;
    }
    &:hover:before{
        font-size: 110px;
    }
    &:hover{
        box-shadow: 2px 2px 4px rgba(0,0,0,.4);
    }
}
</style>