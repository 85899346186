<template>
    <div class="main-view-container" style="position: relative;">
        <div class="main-view-heading" style="padding: 20px 15px; margin-bottom: 0;">
            <h1 style="display: flex; align-items: center; margin: 0;">
                <button tag="button" class="btn btn-secondary" style="margin-right: 10px;" @click="$router.push('/transmitters/' + $route.params.code + '/change-status')">
                    <i class="fas fa-arrow-left"></i> Powrót
                </button>
                Zmiana statusu magazyn-zapas
            </h1>
        </div>
        <transition name="fade" mode="out-in">
            <jsk-spinner :transparent="true" :static="true" v-if="loading">Pobieranie danych</jsk-spinner>
            <div class="main-view-content-container" style="padding:30px 15px; justify-content: flex-start" v-else>
                <div class="col-1-3">
                    <div class="cs-i-heading">
                        Przekaźnik
                    </div>
                    <div class="cs-i-group">
                        <div class="cs-i-group-name">
                            Kod przekaźnika
                        </div>
                        <div class="cs-i-group-value">
                            {{transmitterA.transmitterCode}}
                        </div>
                    </div>
                    <div class="cs-i-group">
                        <div class="cs-i-group-name">
                            Kod OTP przekaźnika
                        </div>
                        <div class="cs-i-group-value">
                            {{transmitterA.otpCode}}
                        </div>
                    </div>
                    <div class="cs-i-group">
                        <div class="cs-i-group-name">
                            Status
                        </div>
                        <div class="cs-i-group-value">
                            {{transmitterA.status}}
                        </div>
                    </div>
                    <div class="cs-i-group">
                        <div class="cs-i-group-name">
                            Lokalizacja
                        </div>
                        <div class="cs-i-group-value">
                            {{transmitterA.cache.location}}
                        </div>
                    </div>
                    <div class="cs-i-group">
                        <div class="cs-i-group-name">
                            Stojak
                        </div>
                        <div class="cs-i-group-value">
                            {{transmitterA.stand}}
                        </div>
                    </div>
                </div>
                <div class="col-1-3" style="display: flex; align-items: flex-start; justify-content: center">
                    <transition name="fade">
                        <div v-if="transmitterA.transmitterCode" style="padding: 0 15px;">
                            <p style="font-weight: 600; margin-top: 0">Lokalizacja dla przekaźnika, którego status ma zostać zmieniony na 'Zapas'</p>
                            <model-list-select
                                :list="locations"
                                option-value="_id"
                                option-text="district"
                                v-model="selectedLocation"
                                placeholder="Lokalizacja przekaźnika"
                                @searchchange="searchLocations"
                                :isError="locationError"
                            >
                            </model-list-select>
                            <p style="font-weight: 600;">
                                Zatwierdź zmianę
                            </p>
                            <button class="btn btn-primary" @click="swapStatuses()" style="padding: 30px 15px">
                                <i class="fas fa-exchange-alt"></i> Zmień status przekaźnika
                            </button>
                        </div>
                    </transition>
                </div>
                <div class="col-1-3"></div>
            </div>
        </transition>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import jskSpinner from '../components/Spinner.vue';
import {ModelListSelect} from 'vue-search-select';

export default {
    data(){
        return{
            loading: false,

            transmitterA: {},

            locationError: false,
            selectedLocation: '',
            locations: []
        }
    },
    components: {
        jskSpinner,
        ModelListSelect
    },
    methods: {
        ...mapActions([
            "GET",
            "POST"
        ]),
        searchLocations(s){
            this.GET({
                url: "/locations?s=" + s
            })
            .then(res => {
                this.locations = res.data.locations;
            })
            .catch(e => {
                console.error(e);
            })
        },
        swapStatuses(){
            this.locationError = false;
            if(!this.transmitterA.otpCode) return false;
            if(this.selectedLocation == ''){
                this.locationError = true;
                return false;
            }
            this.loading = true;

            this.POST({
                url: "/transmitters/changeStatus/from/magazyn/to/zapas",
                data: {
                    code: this.transmitterA.transmitterCode,
                    otpCode: this.transmitterA.otpCode,
                    location: this.selectedLocation
                }
            })
            .then(res => {
                this.$store.commit('newSuccess', res.msg);
                this.loading = false;
                this.$router.push("/transmitters/" + this.transmitterA.transmitterCode + '/details');
            })
            .catch(e => {
                this.loading = false;
                console.error(e);
            });
        }
    },
    created(){
        this.loading = true;

        this.GET({
            url: '/transmitters/' + this.$route.params.code
        })
        .then(res => {
            this.transmitterA = res.data.transmitter;
            this.loading = false;
        })
        .catch(e => {
            console.error(e);
        })
    }
}
</script>