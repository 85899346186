<template>
    <div class="main-view-container" style="position: relative;">
        <div class="main-view-heading" style="padding: 20px 15px; margin-bottom: 0;">
            <h1 style="display: flex; align-items: center; margin: 0;">
                <!-- router-link musi mieć atrybut to="", który określa dokąd przekieruje, ale nie trzeba wkładać do niego elementów dodatkowo, bo można określić jakim elementem html ma się stać sam router-link za pomocą atrybutu tag="nazwaTagu" + tak samo z klasami css, co w atrybucie class="" zostanie przełożone na wynikowy element -->
                <router-link tag="button" class="btn btn-secondary" to="/users" style="margin-right: 10px;">
                    <i class="fas fa-arrow-left"></i> Powrót
                </router-link>
                Dodawanie użytkowników
            </h1>
        </div>
        <div class="main-view-content-container">
            <!-- Nie bój się pisać własnej struktury HTML na tych podstronach, nie da rady wszystkiego na tych samych divach postawić -->
            <div class="col-1-3">
                <h2>Utwórz nowe konto użytkownika w systemie</h2>
                <div class="material-input" ref="newuserLogin">
                    <!-- Ten input jest związany z zmienną login za pomocą v-model -->
                    <input type="text" name="login" v-model="login" class="material-input-field" @blur="handleBlur($event)" autocomplete="off">
                    <label for="login" class="material-input-label">Login użytkownika</label>
                    <div class="material-input-border"></div>
                    <div class="material-input-error-msg">
                        Login jest wymagany do utworzenia konta
                    </div>
                </div>
                <label for="">Poziom uprawnień</label>
                <div class="material-input" ref="newuserPermissions">
                    <!-- Ten select jest związany z zmienną permissions za pomocą v-model, w przypadku selecta nie trzeba zmiennej dla każdej opcji, po prostu w tej do niego przypisanej pojawi się tylko jedna wartość (aktualnie wybrana) -->
                    <select v-model="permissions">
                        <option value="">Wybierz opcję</option>
                        <option value="automatician">Automatyk</option>
                        <option value="inspector">Inspektor</option>
                        <option value="admin">Administrator</option>
                        <!-- Poprawiłem value, mówiłem żebyś zajrzała w dokumentacje, one z małej mają być -->
                    </select>
                    <div class="material-input-error-msg">
                        Wybierz odpowiednie uprawnienia
                    </div>
                </div>
                <div style="margin-top: 15px;">
                    <button class="btn btn-primary" style="width: 100%; text-align: center;" @click="addUser()">
                        Utwórz użytkownika
                    </button>
                </div>
            </div>
            <div class="col-2-3" ref="print">
                <h2 style="font-family: 'Raleway'">
                    Informacje o utworzonym koncie
                </h2>
                <!-- Z tej strony mamy taką sytuację:
                    ten div niżej zawiera 3 elementy:
                        -diva który jest pokazywany domyślnie i zawiera tekst zachęcający do akcji
                        - jsk-spinner -> to komponent w postaci spinnera, który jest pokazywany w trakcie przesyłania danych na serwer
                        - diva z szczegółowymi informacjami o utworzonym koncie (pokazywany po pomyślnym utworzeniu konta)
                    pokazywane są na zasadzie if-if-else, wykorzystuje pomocniczą zmienną userStatus, którą będziemy odpowiednio przełączać
                 -->
                <div style="padding: 30px 0; display: flex; align-items: center; font-family: 'Raleway'">
                    <transition name="fade" appear mode="out-in">
                        <div v-if="userStatus == ''" style="text-align: center; width: 100%;">
                            Utwórz konto użytkownika, aby zobaczyć szczegóły.
                        </div>
                        <jsk-spinner v-else-if="userStatus == 'pending'">Tworzenie konta użytkownika</jsk-spinner>
                        <div v-else style="display: flex;">
                            <div style="font-weight: 600; padding-right: 5px; max-width: 60%;">
                                <p>
                                    Login:
                                </p>
                                <p>
                                    Uprawnienia:
                                </p>
                                <p>
                                    Wygenerowane hasło:
                                </p>
                                <p>
                                    Data utworzenia:
                                </p>
                                <p style="margin-top: 25px;">
                                    Proszę zapisać wygenerowane hasło oraz polecić użytkownikowi jego zmianę po pierwszym zalogowaniu
                                </p>
                                <button class="btn btn-secondary" @click="printDetails()">
                                    <i class="fas fa-print"></i> Drukuj
                                </button>
                            </div>
                            <div>
                                <p>
                                    {{createdUser.login}}
                                </p>
                                <p>
                                    {{createdUser.permissions}}
                                </p>
                                <p>
                                    {{createdUser.password}}
                                </p>
                                <p>
                                    {{new Date(createdUser.cDate).toLocaleDateString()}}
                                </p>
                            </div>
                        </div>  
                    </transition>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import {mapActions} from 'vuex';
//import spinnera
import jskSpinner from '../components/Spinner.vue';

export default {
    data(){
        return{
            //zmienna na login z forma
            login: "",
            //zmienna na uprawnienia z selecta
            permissions: "",
            //zmienna statusowa (ta od pokazywania divów z prawej)
            userStatus: "",

            //pusty obiekt gotowy na przyjęcie danych zwrotnych z serwera (info o utworzonym koncie)
            createdUser: {}

        }
    },
    methods: {
    
        ...mapActions([
            "POST"
        ]),
        //funkcja wspomagająca dla tego 1 inputa, olej
        handleBlur(ev) {
            if(ev.srcElement.value == ""){
                ev.srcElement.parentNode.classList.remove('wc');
            }else{
                ev.srcElement.parentNode.classList.add('wc');
            }
        },
        addUser(){
            //na początek czyszczę wiadomości o błędach (material-inputy mają tak, że jak się nałoży na diva głównego klasę incorrect to się robią czerwone i pokazują info o błędzie, więc na początku im to zabieram)
            //korzystam z this.$refs -> jeżeli w htmlu do elementu da się atrybut ref="nazwa" to ren element jest dostępny potem pod this.$refs.nazwa -> nie trzeba kombinować z jakimś getElement
            this.$refs.newuserLogin.classList.remove('incorrect');
            this.$refs.newuserPermissions.classList.remove('incorrect');

            //zmienna stanu czy są errory walidacji
            let err = false;

            //sprawdzenie inputa i selecta, jeżeli w jakimś jest pusto to daje mu klasę incorrect i ustawiam err na true żeby oznaczyć że mamy błąd
            if(this.login == ""){
                this.$refs.newuserLogin.classList.add('incorrect');
                err = true;
            }
            if(this.permissions == ""){
                this.$refs.newuserPermissions.classList.add('incorrect');
                err = true;
            }
            //jeżeli err to przerywamy kod
            if(err) return false;
    
            //zmieniam userStatus na pending, dzięki czemu pojawi się spinner który aktywuje się tylko jeżeli userStatus == 'pending'    
            this.userStatus = "pending";

            this.POST({
                url: "/users",
                data: {
                    //ślemy dane, wg dokumentacji, przelewamy dane ze zmiennych lokalnych, które z kolei są powiązane z inputami
                    login: this.login,
                    permissions: this.permissions,
                }
            })
            .then(res => {
                //udało się!

                //czyszczenie zawartości inputów
                this.login = "";
                this.permissions = "";

                //wyświetlenie wiadomości o powodzeniu w apce (globalnie)
                this.$store.commit('newSuccess', res.msg);

                //do pustego obiektu z data() wkładamy odesłanego przez serwer utworzonego usera
                this.createdUser = res.data.user;

                //teraz tłumaczymy uprawnienia konta na user-friendly
                if(this.createdUser.permissions == 'admin'){
                    this.createdUser.permissions = "Administrator";
                }else if(this.createdUser.permissions == 'inspector'){
                    this.createdUser.permissions = "Inspektor";
                }else{
                    this.createdUser.permissions = "Automatyk";
                }

                //do obiektu usera dodaję hasło, które przyszło oddzielnie (w sensie nie w obiekcie usera tylko obok [patrz docsy!])
                this.createdUser.password = res.data.password;

                //ustawiam status na 'done' i dzięki temu znika spinner i pojawia się sekcja z info o koncie
                this.userStatus = "done";
            })
            .catch(e => {
                //ups, nie wyszło

                console.error(e);
                //ustawiam status na początkowy, bo skoro nie wyszło to nie chcemy ani spinnera ani ekranu z detalimi bo detali nie mamy, więc ustawienie na wartość początkową powoli pokazać ten 1 div
                this.userStatus = "";
            });
        },
        //funkcja drukująca te pobrane szczegóły //też nieważne bo nigdzie tego już nie użyjemy
        printDetails(){
            let openWindow = window.open("", "Szczegóły konta " + this.createdUser.login, "titlebar=yes");

            let tit = document.createElement('title');
            tit.innerText = "Szczegóły konta " + this.createdUser.login;
            openWindow.document.head.appendChild(tit);
            let ht = this.$refs.print.cloneNode(true);
            ht.childNodes[1].childNodes[0].childNodes[0].removeChild(ht.childNodes[1].childNodes[0].childNodes[0].childNodes[5]);
            openWindow.document.write(ht.innerHTML);
            openWindow.document.close();
            openWindow.focus();
            openWindow.print();
            openWindow.close();
        }
    },
    //deklaracja komponentu spinner - jeżeli nie zadeklarujemy komponentu nie można go użyć!
    components: {
        jskSpinner
    }
}
</script>
