<template>
    <div class="itemlist-container" style="position: relative">
        <transition name="fade" appear mode="out-in">
            <div v-if="loading">
                <jsk-spinner :static="true" :transparent="true">
                    Ładowanie użytkowników
                </jsk-spinner>
            </div>
            <div v-else>
                <h2>
                    Wyniki wyszukiwania
                    <span class="item-pages">Strona {{page}} z {{pages}}</span>
                </h2>
                <table class="tb1">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Login</th>
                            <th>Typ konta</th>
                            <th>Data utworzenia konta</th>
                            <th>Akcje</th> 
                        </tr>
                    </thead>
                    <transition-group name="table" tag="tbody">
                        <tr v-for="(user, index) in users" :key="user._id">
                            <td class="n1">
                              {{(page - 1) * 25 + (index + 1)}} 
                            </td>
                            <td>
                                {{user.login}}
                            </td>
                            <td>
                                {{(user.permissions == "admin")? 'Administrator' : (user.permissions == 'automatician')? 'Automatyk' : 'Inspektor'}}
                            </td>
                            <td>
                                {{new Date(user.cDate).toLocaleDateString()}}
                            </td>
                            <td>
                                <button 
                                    style="margin-right: 10px;" 
                                    class="btn btn-secondary"
                                    title="Zmień login użytkownika"
                                    @click="openLoginEditModal(user.login)"
                                >
                                    <i class="fas fa-pencil-alt"></i>
                                </button>
                                <button
                                    class="btn btn-warning"
                                    :title="'Resetuj hasło użytkownika ' + user.login"
                                    style="margin-right: 10px;"
                                    @click="openPassResetModal(user.login)"
                                >
                                    <i class="fas fa-key"></i>
                                </button>
                                <button 
                                    class="btn btn-danger" 
                                    @click="openModal(user._id, user.login)"
                                    title="Usuń konto"
                                >
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </td>   
                        </tr>
                    </transition-group>
                    <tr v-if="users.length == 0" key="noResults">
                        <td colspan="3">Brak wyników wyszukiwania</td>
                    </tr>
                </table>
                <div class="list-navigation" v-if="pages > 1">
                    <div class="jump-group">
                        <router-link 
                            tag="button" 
                            :to="{path: '/users/1', query }" 
                            class="btn btn-secondary"
                            v-if="page > 1"
                        >
                            Pierwsza strona
                        </router-link>
                        <router-link 
                            class="btn btn-secondary" 
                            :to="{path: '/users/' + pages, query }" 
                            tag="button"
                            v-if="page < pages"
                        >
                            Ostatnia strona
                        </router-link>
                    </div>
                    <div class="pag-group">
                        <router-link 
                            tag="button" 
                            class="btn btn-secondary" 
                            :to="{path: '/users/' + (parseInt(page) - 1), query }" 
                            v-if="page > 1"
                        >
                            Poprzednia strona
                        </router-link>
                        <router-link 
                            tag="button" 
                            class="btn btn-secondary" 
                            :to="{path: '/users/' + (parseInt(page) + 1), query }" 
                            v-if="page < pages"
                        >
                            Następna strona
                        </router-link>
                    </div>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div class="jsk-modal" v-if="usersRemoveModal" @click="usersRemoveModal = false">
                <transition appear name="slide">
                    <div class="jsk-modal-window" @click.stop>
                        <div class="jsk-modal-header">
                            Potwierdzenie usunięcia użytkownika
                        </div>
                        <div class="jsk-modal-body">
                            Czy na pewno usunąć użytkownika {{usersToRemove}}? 
                            <b>Uwaga!</b> Usunięcie konta użytkownika nie spowoduje usunięcia historii zmian w przekaźnikach, które edytował 
                        </div>
                        <div class="jsk-modal-footer">
                            <button class="btn btn-secondary" @click="usersRemoveModal = false"><i class="fas fa-ban"></i> Anuluj</button>
                            <button class="btn btn-danger" @click="removeUser(usersToRemove)"><i class="fas fa-trash-alt"></i> Usuń użytkownika</button>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
        <transition name="fade">
            <div class="jsk-modal" v-if="passResetModal" @click="passResetModal = false">
                <transition appear name="slide">
                    <div class="jsk-modal-window" @click.stop>
                        <div class="jsk-modal-header">
                            <transition name="fade" mode="out-in">
                                <div v-if="passResetModalStatus == ''">
                                    Czy zresetować hasło użytkownika <i>{{passResetModalUser}}</i>?
                                </div>
                                <div v-else-if="passResetModalStatus == 'pending'">
                                    Przesyłanie żądania
                                </div>
                                <div v-else>
                                    Hasło użytkownika {{passResetModalUser}} zostało zresetowane
                                </div>
                            </transition>
                        </div>
                        <div class="jsk-modal-body">
                            <transition name="fade" mode="out-in">
                                <div v-if="passResetModalStatus == ''">
                                    Potwierdź reset hasła konta <i>{{passResetModalUser}}</i>. 
                                </div>
                                <jsk-spinner 
                                    :transparent="true" 
                                    :static="true"
                                    v-else-if="passResetModalStatus == 'pending'"
                                >
                                    Przetwarzanie...
                                </jsk-spinner>
                                <div v-else>
                                    Hasło użytkownika <i>{{passResetModalUser}}</i> zostało zresetowane. System wygenerował nowe hasło: <b ref="newPass">{{passResetModalPassword}}</b>
                                    <br>
                                    <button ref="cpBtn" class="btn btn-secondary" style="margin-top: 10px;" @click="copyPass()">
                                        Skopiuj do schowka
                                    </button>
                                </div>
                            </transition>
                        </div>
                        <div class="jsk-modal-footer" v-if="passResetModalStatus == ''">
                            <button class="btn btn-secondary" @click="passResetModal = false"><i class="fas fa-ban"></i> Anuluj</button>
                            <button class="btn btn-danger" @click="resetPassword(passResetModalUser)"><i class="fas fa-key"></i> Resetuj hasło</button>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
        <transition name="fade">
            <div class="jsk-modal" v-if="loginEditModal" @click="loginEditModal = false">
                <transition appear name="slide">
                    <div class="jsk-modal-window" @click.stop>
                        <div class="jsk-modal-header">
                            <transition name="fade" mode="out-in">
                                <div v-if="loginEditModalStatus == ''">
                                    Zmiana loginu użytkownika <small><i>{{loginEditModalUser}}</i></small>
                                </div>
                                <div v-else-if="loginEditModalStatus == 'pending'">
                                    Przesyłanie żądania
                                </div>
                                <div v-else>
                                    Operacja zakończona
                                </div>
                            </transition>
                        </div>
                        <div class="jsk-modal-body">
                            <transition name="fade" mode="out-in">
                                <div v-if="loginEditModalStatus == ''">
                                    <div class="material-input">
                                        <input type="text" name="login" v-model="loginEditModalLogin" class="material-input-field" @blur="handleBlur($event)" autocomplete="off">
                                        <label for="login" class="material-input-label">Nowy login</label>
                                        <div class="material-input-border"></div>
                                        <div class="material-input-error-msg">
                                            To pole nie może być puste
                                        </div>
                                    </div>
                                </div>
                                <jsk-spinner 
                                    :transparent="true" 
                                    :static="true"
                                    v-else-if="loginEditModalStatus == 'pending'"
                                >
                                    Przetwarzanie...
                                </jsk-spinner>
                                <div v-else>
                                    {{loginEditModalMsg}}
                                </div>
                            </transition>
                        </div>
                        <div class="jsk-modal-footer" v-if="loginEditModalStatus == ''">
                            <button class="btn btn-secondary" @click="loginEditModal = false"><i class="fas fa-ban"></i> Anuluj</button>
                            <button class="btn btn-danger" @click="changeLogin(loginEditModalUser)"><i class="fas fa-check"></i> Zmień login</button>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import jskSpinner from '../components/Spinner.vue' 

export default {
    data(){
        return{
            username: "",
            
            loading: false,
            users: [],

            pages: 0,

            newUsers: "",
            newUsersError: false,

            usersRemoveModal: false,
            usersToRemove: "",
            usersToRemoveID: "",

            passResetModal: false,
            passResetModalStatus: '',
            passResetModalUser: '',
            passResetModalPassword: '',

            loginEditModal: false,
            loginEditModalStatus: '',
            loginEditModalUser: '',
            loginEditModalMsg: '',
            loginEditModalLogin: ''
        }
    },
    computed: {
        page(){
            return this.$route.params.num;
        },
        query(){
            return this.$route.query;
        },
        user(){
            return this.$store.getters.getUser;
        },
    },  
    methods: {
        ...mapActions([
            'GET',
            'POST',
            'DELETE',
            'PUT'
        ]),
        fetchData(spinner){
            if(spinner) this.loading = true;
            
            const url = "/users?page=" + this.$route.params.num + ((this.query.s)? "&s=" + this.query.s : "") + ((this.query.sort)? "&sort=" + this.query.sort : "&sort=id") + ((this.query.sortDirection)? "&sortDirection=" + this.query.sortDirection : "&sortDirection=desc");

            //console.log(url)
            this.GET({
                url
            })
            .then(res => {
                this.users = res.data.users;
                this.pages = res.data.pages;
                if(spinner) this.loading = false;
            })
            .catch(e => {
                console.error(e);
            });
        },
        removeUser(login){
            this.DELETE({
                url: '/users/' + login
            })
            .then(res => {
                this.users.splice(this.users.findIndex(item => {
                    return item.login == login;
                }), 1);
                this.$store.commit('newSuccess', res.msg);
                this.usersRemoveModal = false;
                this.usersToRemove = "";
                this.usersToRemoveID = "";
            })
            .catch(e => {
                console.error(e);
                this.usersRemoveModal = false;
                this.usersToRemove = "";
                this.usersToRemoveID = "";
            })
        },
        openModal(id, name){
            this.usersRemoveModal = true;
            this.usersToRemove = name;
            this.usersToRemoveID = id;
        },
        openPassResetModal(login){
            this.passResetModalUser = login;
            this.passResetModal = true;
            this.passResetModalStatus = '';
        },
        openLoginEditModal(login){
            this.loginEditModalUser = login;
            this.loginEditModal = true;
            this.loginEditModalStatus = '';
            this.loginEditModalMsg = '';
            this.loginEditModalLogin = '';
        },
        copyPass(){
            navigator.clipboard.writeText(this.$refs.newPass.innerText).then(() => {
                this.$refs.cpBtn.innerText = 'Skopiowano!';
            });
        },
        resetPassword(login){
            this.passResetModalStatus = 'pending';
            
            this.POST({
                url: '/users/' + login + '/reset-password'
            })
            .then(res => {
                this.passResetModalPassword = res.data.password;
                this.passResetModalStatus = 'done';
            })
            .catch(e => {
                console.error(e);
                this.passResetModalUser = '';
                this.passResetModal = false;
                this.passResetModalStatus = '';
            });
        },
        changeLogin(login){
            this.loginEditModalStatus = 'pending';
            
            this.PUT({
                url: '/users/' + login,
                data: {
                    login: this.loginEditModalLogin
                }
            })
            .then(res => {
                this.loginEditModalMsg = res.msg;
                this.loginEditModalStatus = 'done';
                this.users[this.users.findIndex(item => item.login == this.loginEditModalUser)].login = this.loginEditModalLogin.toLowerCase();
            })
            .catch(e => {
                this.loginEditModalMsg = e;
                this.loginEditModalStatus = 'done';
            });
        },
        handleBlur(ev) {
            if(ev.srcElement.value == ""){
                ev.srcElement.parentNode.classList.remove('wc');
            }else{
                ev.srcElement.parentNode.classList.add('wc');
            }
        }
    },
    components: {
        jskSpinner
    },
    created(){
        this.fetchData(true);
        this.$parent.$on('refreshUsers', () => {
            this.fetchData(false)
        });
    }
}
</script>
