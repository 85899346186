<template>
    <div class="main-view-container" style="position: relative;">
        <div class="main-view-heading" style="padding: 20px 15px; margin-bottom: 0;">
            <h1 style="display: flex; align-items: center; margin: 0;">
                <button tag="button" class="btn btn-secondary" style="margin-right: 10px;" @click="$router.back();">
                    <i class="fas fa-arrow-left"></i> Powrót
                </button>
                Szczegóły dla przekaźnika {{transmitter.serialNumber}}
            </h1>
        </div>
        <transition name="fade" mode="out-in">
            <jsk-spinner :transparent="true" :static="true" v-if="loading">Pobieranie danych</jsk-spinner>
            <div class="main-view-content-container" style="padding:30px 15px; justify-content: flex-start" v-else>
                <div class="td-subdetails-heading">
                    Podstawowe dane
                </div>
                <div class="col-1-3">
                    <div class="td-field">
                        <div class="td-field-name">
                            Typ przekaźnika
                        </div>
                        <div class="td-field-value">
                            {{transmitter.cache.transmitterType}}
                        </div>
                    </div>
                </div>
                <div class="col-1-3">
                    <div class="td-field">
                        <div class="td-field-name">
                            Numer seryjny
                        </div>
                        <div class="td-field-value">
                            {{transmitter.serialNumber}}
                        </div>
                    </div>
                </div>
                <div class="col-1-3">
                    <div class="td-field">
                        <div class="td-field-name">
                            Odmiana przekaźnika
                        </div>
                        <div class="td-field-value">
                            {{transmitter.variant}}
                        </div>
                    </div>
                </div>
                <div class="col-1-3">
                    <div class="td-field">
                        <div class="td-field-name">
                            Kod przekaźnika
                        </div>
                        <div class="td-field-value">
                            {{transmitter.transmitterCode}}
                        </div>
                    </div>
                </div>
                <div class="col-1-3">
                    <div class="td-field">
                        <div class="td-field-name">
                            Rok produkcji
                        </div>
                        <div class="td-field-value">
                            {{new Date(transmitter.productionDate).getFullYear()}}
                        </div>
                    </div>
                </div>
                <div class="col-1-3">
                    <div class="td-field">
                        <div class="td-field-name">
                            Data wprowadzenia do systemu
                        </div>
                        <div class="td-field-value">
                            {{new Date(transmitter.cDate).toLocaleDateString()}}
                        </div>
                    </div>
                </div>
                <div class="td-subdetails-heading">
                    Szczegóły OTP
                </div>
                <div class="col-1-3">
                    <div class="td-field">
                        <div class="td-field-name">
                            Kod OTP
                        </div>
                        <div class="td-field-value">
                            {{transmitter.otpCode}}
                        </div>
                    </div>
                </div>
                <div class="col-1-3">
                    <div class="td-field">
                        <div class="td-field-name">
                            Status OTP
                        </div>
                        <div class="td-field-value">
                            <span style="padding: 5px 10px; border-radius: 3px; color: #fff; margin-bottom: 5px; display: inline-block;" :class="{'bg-primary': (transmitter.otpStatus == 'OK'), 'bg-warning': (transmitter.otpStatus == 'OK+3'), 'bg-danger': (transmitter.otpStatus == 'NOK')}">
                                {{transmitter.otpStatus}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-1-3">
                    <div class="td-field">
                        <div class="td-field-name">
                            Resurs OTP
                        </div>
                        <div class="td-field-value">
                            {{transmitter.otpResurs}} [lata]
                        </div>
                    </div>
                </div>
                <div class="col-1-3">
                    <div class="td-field">
                        <div class="td-field-name">
                            Data ostatniej kontroli OTP
                        </div>
                        <div class="td-field-value">
                            {{new Date(transmitter.otpDate).toLocaleDateString()}}
                        </div>
                    </div>
                </div>
                <div class="col-1-3">
                    <div class="td-field">
                        <div class="td-field-name">
                            Data kolejnej kontroli OTP
                        </div>
                        <div class="td-field-value">
                            {{new Date(transmitter.nextOtpDate).toLocaleDateString()}}
                        </div>
                    </div>
                </div>
                <div class="td-subdetails-heading">
                    Status przekaźnika
                </div>
                <div class="col-1-3">
                    <div class="td-field">
                        <div class="td-field-name">
                            Status przekaźnika
                        </div>
                        <div class="td-field-value">
                            {{(transmitter.status == 'otp')? "OTP" : (transmitter.status =='zapas')? "Zapas" : (transmitter.status == 'stojak')? "Stojak" : "Magazyn"}}
                        </div>
                    </div>
                </div>
                <div class="col-1-3" v-if="transmitter.status == 'stojak'">
                    <div class="td-field">
                        <div class="td-field-name">
                            Stojak
                        </div>
                        <div class="td-field-value">
                            {{transmitter.stand}}
                        </div>
                    </div>
                </div>
                <div class="col-1-3" v-if="transmitter.status == 'stojak' || transmitter.status == 'zapas'">
                    <div class="td-field">
                        <div class="td-field-name">
                            Lokalizacja
                        </div>
                        <div class="td-field-value">
                            {{transmitter.cache.location}}
                        </div>
                    </div>
                </div>
                <div class="td-subdetails-heading">
                    Historia zmian
                </div>
                <div class="td-t-history">
                    <div class="td-t-history-item" v-for="change in transmitter.changelog" :key="change._id">
                        <div class="td-t-history-item-left">
                            <div class="td-field-name">
                                Data modyfikacji
                            </div>
                            <div class="td-field-value" style="margin-bottom: 25px;">
                                {{new Date(change.cDate).toLocaleString()}}
                            </div>
                            <div class="td-field-name">
                                Użytkownik
                            </div>
                            <div class="td-field-value">
                                {{change.user}}
                            </div>
                        </div>
                        <div class="td-t-history-item-right">
                            <div class="td-history-list-heading">
                                Wprowadzone zmiany
                            </div>
                            <div class="td-history-list-list">
                                <div class="td-history-list-list-item" v-for="c in change.changes" :key="c">
                                    {{c}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="td-subdetails-heading" v-if="$store.getters.getUser.permissions != 'automatician'">
                    Akcje
                </div>
                <div style="width: 100%;" v-if="$store.getters.getUser.permissions != 'automatician'">
                    <router-link 
                        tag="button" 
                        class="btn btn-warning" 
                        :to="'/transmitters/' + $route.params.code + '/edit'"
                        v-if="$store.getters.getUser.permissions == 'admin'"
                        style="margin-right: 10px;"
                    >
                        <i class="fas fa-pencil-alt"></i> Edytuj przekaźnik
                    </router-link>
                    <router-link 
                        tag="button" 
                        :to="'/transmitters/' + $route.params.code + '/change-status'"
                        class="btn btn-secondary" 
                    >
                        <i class="fas fa-clipboard-list"></i> Zmiana statusu
                    </router-link>
                    <button 
                        class="btn btn-danger" 
                        style="margin-left: 10px;"
                        @click="openModal()"
                        v-if="$store.getters.getUser.permissions == 'admin'"
                    >
                        <i class="fas fa-trash-alt"></i> Usuń przekaźnik
                    </button>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div class="jsk-modal" v-if="removeModal" @click="removeModal = false">
                <transition appear name="slide">
                    <div class="jsk-modal-window" @click.stop>
                        <div class="jsk-modal-header">
                            Potwierdzenie usunięcia przekaźnika
                        </div>
                        <div class="jsk-modal-body">
                            Czy na pewno usunąć przekaźnik {{ttRem}}? <b>Uwaga!</b> Spowoduje to jego całkowite i bezpowrotne usunięcie z systemu!
                        </div>
                        <div class="jsk-modal-footer">
                            <button class="btn btn-secondary" @click="removeModal = false"><i class="fas fa-ban"></i> Anuluj</button>
                            <button class="btn btn-danger" @click="removeTransmitter()"><i class="fas fa-trash-alt"></i> Usuń Przekaźnik</button>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import jskSpinner from '../components/Spinner.vue'
import {mapActions} from 'vuex'

export default {
    data(){
        return{
            loading: false,

            transmitter: {},

            removeModal: false,
            ttRem: ''
        }
    },
    methods: {
        ...mapActions([
            "GET",
            "DELETE"
        ]),
        fetchData(){
            this.loading = true;

            this.GET({
                url: '/transmitters/' + this.$route.params.code
            })
            .then(res => {
                res.data.transmitter.changelog = res.data.transmitter.changelog.reverse();
                this.transmitter = res.data.transmitter;
                this.loading = false;
            })
            .catch(e => {
                console.error(e);
            })
        },
        openModal(){
            this.ttRem = this.transmitter.transmitterCode;
            this.removeModal = true;
        },
        removeTransmitter(){
            this.DELETE({
                url: "/transmitters/" + this.$route.params.code
            })
            .then(res => {
                this.removeModal = false;
                this.$store.commit('newSuccess', res.msg);
                this.$router.push("/transmitters/1");
            })
            .catch(e => {
                this.removeModal = false;
                console.error(e);
            })
        }
    },
    components: {
        jskSpinner
    },
    created(){
        this.fetchData();
    }
}
</script>

<style lang="scss">
.td-subdetails-heading{
    font-size: 18px;
    position: relative;
    padding: 2px 0;
    margin: 0 0 25px;
    width: 100%;

    &:not(:first-child){
        padding-top: 25px;
        border-top: 1px solid #ddd;
    }

    &:after{
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 50px;
        height: 2px;
        background-color: #27AE60;
    }
}

.td-field{
    margin-bottom: 15px;

    &-name{
        font-weight: 600;
        margin-bottom: 5px;
    }
    
    &-value{
        font-size: 16px;
    }
}

.td-t-history{
    width: 100%;
    max-height: 800px;
    overflow-y: auto;
    box-shadow: 0 -1px 4px 0 rgba(0,0,0,.1) inset;

    &-item{
        display: flex;
        justify-content: space-between;
        padding: 5px;

        &:last-child{
            margin-bottom: 30px;
        }

        &:nth-child(even){
            background-color: #eee;
        }

        &-left{
            width: calc(30% - 15px);
        }

        &-right{
            width: calc(70% - 15px);
        }

        .td-history-list-heading{
            font-weight: 600;
            margin-bottom: 10px;
        }

        .td-history-list-list{
            padding-left: 15px;
        }
    }
}
</style>

