<template>
    <div class="main-view-container" style="position: relative;">
        <div class="main-view-heading" style="padding: 20px 15px; margin-bottom: 0;">
            <h1 style="display: flex; align-items: center; margin: 0;">
                <button tag="button" class="btn btn-secondary" style="margin-right: 10px;" @click="$router.push('/transmitters/' + $route.params.code + '/change-status')">
                    <i class="fas fa-arrow-left"></i> Powrót
                </button>
                Zmiana statusu OTP-magazyn
            </h1>
        </div>
        <transition name="fade" mode="out-in">
            <jsk-spinner :transparent="true" :static="true" v-if="loading">Pobieranie danych</jsk-spinner>
            <div class="main-view-content-container" style="padding:30px 15px; justify-content: flex-start" v-else>
                <div class="col-1-3">
                    <div class="cs-i-heading">
                        Przekaźnik 1
                    </div>
                    <div class="cs-i-group">
                        <div class="cs-i-group-name">
                            Kod przekaźnika
                        </div>
                        <div class="cs-i-group-value">
                            {{transmitterA.transmitterCode}}
                        </div>
                    </div>
                    <div class="cs-i-group">
                        <div class="cs-i-group-name">
                            Kod OTP przekaźnika
                        </div>
                        <div class="cs-i-group-value">
                            {{transmitterA.otpCode}}
                        </div>
                    </div>
                    <div class="cs-i-group">
                        <div class="cs-i-group-name">
                            Status
                        </div>
                        <div class="cs-i-group-value">
                            {{transmitterA.status.toUpperCase()}}
                        </div>
                    </div>
                </div>
                <div class="col-2-3" style="display: flex; align-items: flex-start; justify-content: center">
                    <transition name="fade">
                        <div v-if="transmitterA.transmitterCode" style="padding: 0 15px; width: 100%">
                            <p style="font-weight: 600; margin-top: 0">Uzupełnij przekaźnik o nowe dane:</p>
                            <div class="material-input" ref="noc">
                                <input type="text" v-model="nOtpCode" class="material-input-field" @blur="handleBlur($event)" autocomplete="off">
                                <label for="login" class="material-input-label">Nowy Kod OTP</label>
                                <div class="material-input-border"></div>
                                <div class="material-input-error-msg">
                                    Wypełnij to pole, typ zawartości: liczbowy [8 cyfr]
                                </div>
                            </div>
                            <div class="material-input wc" ref="nod">
                                <input type="text" v-model="nOtpDate" class="material-input-field" @blur="handleBlur($event)" autocomplete="off">
                                <label for="login" class="material-input-label">Data kontroli OTP [RRRR-MM-DD]</label>
                                <div class="material-input-border"></div>
                                <div class="material-input-error-msg">
                                    Wypełnij to pole, format daty RRRR-MM-DD
                                </div>
                            </div>
                            <p style="font-weight: 600;">
                                Zatwierdź zmianę
                            </p>
                            <button class="btn btn-primary" @click="swapStatuses()" style="padding: 30px 15px">
                                <i class="fas fa-exchange-alt"></i> Zmień status przekaźnika
                            </button>
                        </div>
                    </transition>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import jskSpinner from '../components/Spinner.vue';

export default {
    data(){
        return{
            loading: false,

            transmitterA: {},
           
            nOtpCode: '',
            nOtpDate: ''
        }
    },
    components: {
        jskSpinner
    },
    methods: {
        ...mapActions([
            "GET",
            "POST"
        ]),
        handleBlur(ev) {
            if(ev.srcElement.value == ""){
                ev.srcElement.parentNode.classList.remove('wc');
            }else{
                ev.srcElement.parentNode.classList.add('wc');
            }
        },
        padToTwo(n){
            n = parseInt(n);
            if(isNaN(n)) return "";

            if(n < 10){
                return "0" + n.toString();
            }else{
                return n.toString();
            }
        },
        
        swapStatuses(){
            if(!this.transmitterA.otpCode) return false;
            
            this.$refs.noc.classList.remove('incorrect');
            this.$refs.nod.classList.remove('incorrect');

            let err = false;

            if(this.nOtpCode == '' || isNaN(this.nOtpCode) || this.nOtpCode.split("").length != 8){
                this.$refs.noc.classList.add('incorrect');
                err = true;
            }
            const dateTest = new RegExp(/^\d{4}-\d{2}-\d{2}$/g);
            if(this.nOtpDate == '' || !dateTest.test(this.nOtpDate)){
                this.$refs.nod.classList.add('incorrect');
                err = true;
            }else{
                let tday = new Date(),
                    nOtpD = new Date(this.nOtpDate);

                if(tday.getFullYear() < nOtpD.getFullYear() || tday.getMonth() < nOtpD.getMonth() || tday.getDate() < nOtpD.getDate()){
                    this.$refs.nod.classList.add('incorrect');
                    err = true;
                }
            }

            if(err) return false;

            this.loading = true;

            this.POST({
                url: "/transmitters/changeStatus/from/otp/to/magazyn",
                data: {
                    code: this.transmitterA.transmitterCode,
                    newOtpCode: this.nOtpCode,
                    otpDate: this.nOtpDate
                }
            })
            .then(res => {
                this.nOtpCode = '';
                this.nOtpDate = '';
                this.$store.commit('newSuccess', res.msg);
                this.loading = false;
                this.$router.push("/transmitters/" + this.transmitterA.transmitterCode + '/details');
            })
            .catch(e => {
                this.loading = false;
                this.nOtpCode = '';
                this.nOtpDate = '';
                console.error(e);
            });
        }
    },
    created(){
        this.loading = true;

        this.GET({
            url: '/transmitters/' + this.$route.params.code
        })
        .then(res => {
            this.transmitterA = res.data.transmitter;
            this.loading = false;
        })
        .catch(e => {
            console.error(e);
        });

        let d = new Date();
        this.nOtpDate = d.getFullYear() + "-" + this.padToTwo(d.getMonth() + 1) + "-" + this.padToTwo(d.getDate());
    }
}
</script>
