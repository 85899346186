import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import Store from './store'
import e404 from './views/404.vue'
import Locations from './views/Locations.vue'
import LocationsList from './views/LocationsList.vue'
import TransmittersTypesList from './views/TransmittersTypesList.vue'
import TransmittersTypes from './views/TransmittersTypes.vue'
import Users from './views/Users.vue'
import UsersList from './views/UsersList.vue'
import newUser from './views/UsersNewUser.vue'
import TransmittersList from './views/TransmittersList.vue'
import Transmitters from './views/Transmitters.vue'
import TransmittersNewTransmitter from './views/TransmittersNewTransmitter.vue'
import TransmittersEditTransmitter from './views/TransmittersEditTransmitter.vue'
import TransmitterDetails from './views/TransmitterDetails.vue'
import TransmittersStatuses from './views/TransmittersStatus.vue'
import TransmittersStatusesStandStand from './views/TransmittersStatusesStandStand.vue'
import TransmittersStatusesStandSupply from './views/TransmittersStatusesStandSupply.vue'
import TransmittersStatusesStoreSupply from './views/TransmittersStatusesStockSupply.vue'
import TransmittersStatusesOtpStock from './views/TransmittersStatusesOtpStock.vue'
import Account from './views/Account.vue'



Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: "/account",
      name: 'account',
      component: Account
    },
    {
      path: '/login',
      name: 'login',
      component: Login
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      //component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path: '/transmitters/new',
      name: "newTransmitter",
      component: TransmittersNewTransmitter,
      beforeEnter(to, from, next) {
        if(Store.getters.getUser.permissions != 'admin'){
          next(false)
        }else{
          next()
        }
      }
    },
    {
      path: "/transmitters/:code/details",
      name: "TransmittersDetails",
      component: TransmitterDetails,
      // beforeEnter(to, from, next) {
      //   if(Store.getters.getUser.permissions == 'automatician'){
      //     next(false)
      //   }else{
      //     next()
      //   }
      // }
    },
    {
      path: "/transmitters/:code/edit",
      name: "TransmitterEdit",
      component: TransmittersEditTransmitter,
      beforeEnter(to, from, next) {
        if(Store.getters.getUser.permissions != 'admin'){
          next(false)
        }else{
          next()
        }
      }
    },
    {
      path: '/transmitters/:code/change-status',
      name: "TransmittersStatuses",
      component: TransmittersStatuses,
      beforeEnter(to, from, next) {
        if(Store.getters.getUser.permissions == 'automatician'){
          next(false)
        }else{
          next()
        }
      }
    },
    {
      path: '/transmitters/:code/change-status/stand/stand',
      name: "TransmittersStatusesStandStand",
      component: TransmittersStatusesStandStand,
      beforeEnter(to, from, next) {
        if(Store.getters.getUser.permissions == 'automatician'){
          next(false)
        }else{
          next()
        }
      }
    },
    {
      path: '/transmitters/:code/change-status/supply/stand',
      name: "TransmittersStatusesStandSupply",
      component: TransmittersStatusesStandSupply,
      beforeEnter(to, from, next) {
        if(Store.getters.getUser.permissions == 'automatician'){
          next(false)
        }else{
          next()
        }
      }
    },
    {
      path: '/transmitters/:code/change-status/stock/supply',
      name: "TransmittersStatusesStoreSupply",
      component: TransmittersStatusesStoreSupply,
      beforeEnter(to, from, next) {
        if(Store.getters.getUser.permissions == 'automatician'){
          next(false)
        }else{
          next()
        }
      }
    },
    {
      path: '/transmitters/:code/change-status/otp/stock',
      name: "TransmittersStatusesOtpStock",
      component: TransmittersStatusesOtpStock,
      beforeEnter(to, from, next) {
        if(Store.getters.getUser.permissions == 'automatician'){
          next(false)
        }else{
          next()
        }
      }
    },
    {
      path: '/transmitters',
      component: Transmitters,
      children: [
        {
          path: "",
          beforeEnter(to, from, next) {
            next("/transmitters/1");
          }
        },
        {
          path: ":num",
          beforeEnter(to, from, next) {
            if (isNaN(parseInt(to.params.num))) {
              next(false);
            } else {
              next();
            }
          },
          name: "transmittersList",
          component: TransmittersList,
        }
      ]
    },
    {
      path: '/locations',
      component: Locations,
      children: [
        {
          path: "",
          beforeEnter(to, from, next) {
            next("/locations/1");
          }
        },
        {
          path: ":num",
          beforeEnter(to, from, next) {
            if (isNaN(parseInt(to.params.num))) {
              next(false);
            } else {
              next();
            }
          },
          name: "locationsList",
          component: LocationsList
        }
      ]
    }, {
      path: '/transmitters-types',
      component: TransmittersTypes,
      children: [
        {
          path: "",
          beforeEnter(to, from, next) {
            next("/transmitters-types/1");
          }
        },
        {
          path: ":num",
          beforeEnter(to, from, next) {
            if (isNaN(parseInt(to.params.num))) {
              next(false);
            } else {
              next();
            }
          },
          name: "transmitterslist",
          component: TransmittersTypesList,
        }
      ]
    },
    {
      path: "/users/new",
      name: "newUser",
      component: newUser,
      beforeEnter(to, from, next) {
        if(Store.getters.getUser.permissions == 'admin'){
          next()
        }else{
          next(false)
        }
      }
    },
    {
      path: '/users',
      component: Users,
      beforeEnter(to, from, next) {
        if(Store.getters.getUser.permissions == 'admin'){
          next()
        }else{
          next(false)
        }
      },
      children: [
        {
          path: "",
          beforeEnter(to, from, next) {
            next("/users/1");
          }
        },
        {
          path: ":num",
          beforeEnter(to, from, next) {
            if (isNaN(parseInt(to.params.num))) {
              next(false);
            } else {
              next();
            }
          },
          name: "userslist",
          component: UsersList,
        }
      ]
    },
    {
      path: '*',
      name: '404',
      component: e404
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.path == "/login") {
    if (Store.getters.isLoggedIn == 'true') {
      next(false)
    } else {
      Store.dispatch('loginFromStorage');
      if (Store.getters.isLoggedIn == 'true') {
        next(false);
      } else {
        next();
      }
    }
  } else {
    if (Store.getters.isLoggedIn == 'true') {
      next();
    } else {
      Store.dispatch('loginFromStorage');
      if (Store.getters.isLoggedIn == 'true') {
        next();
      } else {
        next("/login");
      }
    }
  }
});

export default router;
