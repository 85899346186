<template>
    <div class="main-view-container" style="position: relative;">
        <div class="main-view-heading">
            <h1 style="margin-bottom: 0;">Moje konto</h1>
        </div>
        <div class="item-list-settings">
            <div class="item-list-settings-group">
                <h4 style="margin-bottom: 25px;">Dane konta</h4>
                <div style="margin-bottom: 15px;">
                    <div style="margin-bottom: 5px;">
                        Login:
                    </div>
                    <div>
                        <b>{{user.username}}</b>
                    </div>
                </div>
                <div>
                    <div style="margin-bottom: 5px;">
                        Uprawnienia:
                    </div>
                    <div>
                        <b>
                            {{(user.permissions == "admin")? 'Administrator' : (user.permissions == 'automatician')? 'Automatyk' : 'Inspektor'}}
                        </b>
                    </div>
                </div>
            </div>
            <div class="item-list-settings-group">
                <h4 style="margin-bottom: 25px;">Zmiana hasła</h4>
                <div class="material-input" ref="op">
                    <input type="password" name="oldpasswd" v-model="oldpassword" class="material-input-field" @blur="handleBlur($event)">
                    <label for="oldpasswd" class="material-input-label">Obecne hasło</label>
                    <div class="material-input-border"></div>
                    <div class="material-input-error-msg">
                        To pole jest wymagane!
                    </div>
                </div>
                <div class="material-input" ref="np1">
                    <input type="password" name="newpasswd1" v-model="newpassword1" class="material-input-field" @blur="handleBlur($event)">
                    <label for="newpasswd1" class="material-input-label">Nowe hasło</label>
                    <div class="material-input-border"></div>
                    <div class="material-input-error-msg">
                        To pole jest wymagane!
                    </div>
                </div>
                <div class="material-input" ref="np2">
                    <input type="password" name="newpasswd2" v-model="newpassword2" class="material-input-field" @blur="handleBlur($event)">
                    <label for="newpasswd2" class="material-input-label">Powtórz nowe hasło</label>
                    <div class="material-input-border"></div>
                    <div class="material-input-error-msg">
                        To pole jest wymagane!
                    </div>
                </div>
                <button class="btn btn-danger" @click="changePassword()">
                    <span v-if="changingPending">
                        <i class="fas fa-circle-notch fa-spin"></i>
                    </span>
                    <span v-else>
                        Zmień hasło
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      oldpassword: '',
      newpassword1: '',
      newpassword2: '',

      changingPending: false
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    }
  },
  methods: {
    ...mapActions(["POST"]),
    handleBlur(ev) {
        if(ev.srcElement.value == ""){
            ev.srcElement.parentNode.classList.remove('wc');
        }else{
            ev.srcElement.parentNode.classList.add('wc');
        }
    },
    changePassword(){
        this.$refs.op.classList.remove('incorrect');
        this.$refs.np1.classList.remove('incorrect');
        this.$refs.np2.classList.remove('incorrect');

        let err = false;

        if(this.oldpassword == ""){
            this.$refs.op.classList.add('incorrect');
            err = true;
        }
        if(this.newpassword1 == ""){
            this.$refs.np1.classList.add('incorrect');
            err = true;
        }
        if(this.newpassword2 == ""){
            this.$refs.np2.classList.add('incorrect');
            err = true;
        }
        if(err) return;

        this.changingPending = true;

        this.POST({
            url: '/account',
            data: {
                oldPassword: this.oldpassword,
                newPassword1: this.newpassword1,
                newPassword2: this.newpassword2
            }
        })
        .then(res => {
            this.changingPending = false;
            this.oldpassword = '';
            this.newpassword1 = '';
            this.newpassword2 = '';
            this.$refs.op.classList.remove('wc');
            this.$refs.np1.classList.remove('wc');
            this.$refs.np2.classList.remove('wc');
            this.$store.commit('newSuccess', res.msg);
        })
        .catch(err => {
            this.changingPending = false;
            console.error(err);
        })
    }
  }
};
</script>
<style>
</style>
