import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Axios from "axios";

Vue.config.productionTip = false;
// Axios.defaults.baseURL = "http://localhost:9343/api";
// Axios.defaults.baseURL = "https://jsk2.zs6sobieski.edu.pl/api";
Axios.defaults.baseURL = "/api";

Axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status == 401) {
            alert(
                "Ups! Wygląda na to, że Twój token wygasł. Najprawdopodobniej właśnie minęła północ. Zaraz zostaniesz wylogowany. Zaloguj się ponownie aby odnowić token."
            );
            store.commit("unsetAuthData");
            store.state.messages.errors = [];
            store.state.messages.successes = [];
            router.push("/login");
        }
        return Promise.reject(error);
    }
);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
