<template>
    <div>
        <div class="main-view-container" style="position: relative">
            <div class="main-view-heading">
                <h1>Użytkownicy</h1>
                <div class="main-view-add-item">
                    <router-link to="/users/new" tag="div">
                        <button class="btn btn-primary">
                            <i class="fas fa-user-plus"></i> Nowy użytkownik
                        </button>
                    </router-link>
                </div>
            </div>
            <div class="item-list-settings">
                <div class="item-list-settings-group filters">
                    <h4>Filtry</h4>
                    <div class="item-list-settings-line">
                        <span> Szukaj w bazie <i>Użytkownicy</i> </span>
                        <span class="sl-fl1">
                            <input
                                type="text"
                                placeholder="Szukana fraza"
                                v-model="searchTerm"
                                autocomplete="off"
                                @keydown.enter="filter()"
                            />
                            <span class="incorrect-message" :class="{ showed: searchingError }"
                                >To pole nie może być puste</span
                            >
                        </span>
                    </div>
                    <div class="item-list-settings-line">
                        <button class="btn btn-secondary" @click="filter()">
                            Filtruj <i class="fas fa-filter"></i>
                        </button>
                        <button class="btn btn-warning" @click="removeFilter()">
                            Usuń filtry <i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <div class="item-list-settings-group sorting">
                    <h4>Sortowanie</h4>
                    <div class="item-list-settings-line">
                        <span> Sortuj wyniki według pola </span>
                        <span class="sl-fl1">
                            <select v-model="sortTerm">
                                <option value="">Wybierz pole</option>
                                <option value="id|desc">Data utworzenia konta - malejąco</option>
                                <option value="id|asc">Data utworzenia konta - rosnąco</option>
                                <option value="permissions|desc">Uprawnienia - malejąco</option>
                                <option value="permissions|asc">Uprawnienia - rosnąco</option>
                                <option value="login|desc">Login - malejąco</option>
                                <option value="login|asc">Login - rosnąco</option>
                            </select>
                            <span class="incorrect-message" :class="{ showed: sortingError }"
                                >To pole nie może być puste</span
                            >
                        </span>
                    </div>
                    <div class="item-list-settings-line">
                        <button class="btn btn-secondary" @click="sortUsers()">
                            Sortuj <i class="fas fa-sort-amount-up"></i>
                        </button>
                        <button class="btn btn-warning" @click="removeSorting()">
                            Wyczyść <i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>
            <!--TABELA -->
            <router-view :key="$route.fullPath"></router-view>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            username: "",

            searchTerm: "",
            searchingError: false,

            sortTerm: "",
            sortingError: false
        };
    },
    computed: {
        user() {
            return this.$store.getters.getUser;
        }
    },
    methods: {
        ...mapActions(["POST"]),
        filter() {
            this.searchingError = false;
            if (this.searchTerm == "") {
                this.searchingError = true;
                return false;
            }

            this.$router.push({
                query: Object.assign({}, this.$route.query, { s: this.searchTerm }),
                params: {
                    num: 1
                }
            });
        },
        removeFilter() {
            let q = Object.assign({}, this.$route.query);
            delete q.s;
            this.$router.replace({ query: q }).catch(() => {});
            this.searchTerm = "";
        },
        sortUsers() {
            this.sortingError = false;
            if (this.sortTerm == "") {
                this.sortingError = true;
                return false;
            }

            let qa = this.sortTerm.split("|");

            this.$router.push({
                query: Object.assign({}, this.$route.query, { sort: qa[0], sortDirection: qa[1] })
            });
        },
        removeSorting() {
            let q = Object.assign({}, this.$route.query);
            delete q.sort;
            delete q.sortDirection;
            this.$router.replace({ query: q });
        }
    }
};
</script>
