<template>
    <div>
        <div class="main-view-container" style="position: relative">
            <div class="main-view-heading">
                <h1>Lokalizacja</h1>
                <div
                    class="main-view-add-item"
                    v-if="$store.getters.getUser.permissions == 'admin'"
                >
                    <span class="flat-input-group" :class="{ incorrect: emptyDistrict }">
                        <input
                            type="text"
                            placeholder="Okręg nastawczy"
                            v-model="district"
                            autocomplete="off"
                        />
                        <span class="flat-input-incorrect-message">
                            Uzupełnij to pole aby dodać lokalizację
                        </span>
                    </span>
                    <button class="btn btn-primary" @click="addLocation()">
                        <span v-if="addingInProgress">
                            <i class="fas fa-circle-notch fa-spin"></i>
                        </span>
                        <span v-else> + Dodaj lokalizację </span>
                    </button>
                </div>
            </div>
            <div class="item-list-settings">
                <div class="item-list-settings-group filters">
                    <h4>Filtry</h4>
                    <div class="item-list-settings-line">
                        <span> Szukaj w bazie <i>Lokalizacje</i> </span>
                        <span class="sl-fl1">
                            <input
                                type="text"
                                placeholder="Szukana fraza"
                                v-model="searchTerm"
                                autocomplete="off"
                                @keydown.enter="filter()"
                            />
                            <span class="incorrect-message" :class="{ showed: searchingError }"
                                >To pole nie może być puste</span
                            >
                        </span>
                    </div>
                    <div class="item-list-settings-line">
                        <button class="btn btn-secondary" @click="filter()">
                            Filtruj <i class="fas fa-filter"></i>
                        </button>
                        <button class="btn btn-warning" @click="removeFilter()">
                            Usuń filtry <i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <div class="item-list-settings-group sorting">
                    <h4>Sortowanie</h4>
                    <div class="item-list-settings-line">
                        <span> Sortuj wyniki według pola </span>
                        <span class="sl-fl1">
                            <select v-model="sortTerm">
                                <option value="">Wybierz pole</option>
                                <option value="id|desc">Data dodania - malejąco (domyślne)</option>
                                <option value="id|asc">Data dodania - rosnąco</option>
                                <option value="district|desc">Okręg nastawczy - malejąco</option>
                                <option value="district|asc">Okręg nastawczy - rosnąco</option>
                            </select>
                            <span class="incorrect-message" :class="{ showed: sortingError }"
                                >To pole nie może być puste</span
                            >
                        </span>
                    </div>
                    <div class="item-list-settings-line">
                        <button class="btn btn-secondary" @click="sortLocations()">
                            Sortuj <i class="fas fa-sort-amount-up"></i>
                        </button>
                        <button class="btn btn-warning" @click="removeSorting()">
                            Wyczyść <i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <div
                    class="item-list-settings-group export"
                    v-if="user.permissions != 'automatician'"
                >
                    <!-- <h4>Eksport</h4>
                    <div class="item-list-settings-line">
                        <span class="sl-fl1">
                            Eksportuj <i>Lokalizacje</i> do pliku .csv (jeżeli zastosowane zostały filtry, zostaną one uwzględnione podczas eksportu)
                        </span>
                    </div>
                    <div class="item-list-settings-line">
                        <button class="btn btn-secondary" @click="exportData()">
                            Eksportuj <i class="fas" :class="{'fa-circle-notch fa-spin': exporting, 'fa-file-csv': !exporting}"></i>
                        </button>
                    </div> -->
                </div>
            </div>
            <!-- WIDOK TABELI -->
            <router-view :key="$route.fullPath"></router-view>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import Axios from "axios";

export default {
    data() {
        return {
            district: "",
            emptyDistrict: false,
            addingInProgress: false,

            searchTerm: "",
            searchingError: false,

            sortTerm: "",
            sortingError: false,

            exporting: false
        };
    },
    computed: {
        query() {
            return this.$route.query;
        },
        user() {
            return this.$store.getters.getUser;
        }
    },
    methods: {
        ...mapActions(["POST"]),
        addLocation() {
            this.emptyDistrict = false;
            if (this.district == "") {
                this.emptyDistrict = true;
                return false;
            }

            this.addingInProgress = true;

            this.POST({
                url: "/locations",
                data: {
                    district: this.district
                }
            })
                .then(res => {
                    this.addingInProgress = false;
                    this.district = "";
                    this.$store.commit("newSuccess", res.msg);
                    this.$emit("refreshLocations", true);
                })
                .catch(e => {
                    this.addingInProgress = false;
                });
        },
        filter() {
            this.searchingError = false;
            if (this.searchTerm == "") {
                this.searchingError = true;
                return false;
            }

            this.$router.push({
                query: Object.assign({}, this.$route.query, { s: this.searchTerm }),
                params: {
                    num: 1
                }
            });
        },
        removeFilter() {
            let q = Object.assign({}, this.$route.query);
            delete q.s;
            this.$router.replace({ query: q }).catch(() => {});
            this.searchTerm = "";
        },
        sortLocations() {
            this.sortingError = false;
            if (this.sortTerm == "") {
                this.sortingError = true;
                return false;
            }

            let qa = this.sortTerm.split("|");

            this.$router.push({
                query: Object.assign({}, this.$route.query, { sort: qa[0], sortDirection: qa[1] })
            });
        },
        removeSorting() {
            let q = Object.assign({}, this.$route.query);
            delete q.sort;
            delete q.sortDirection;
            this.$router.replace({ query: q });
        },
        exportData() {
            const url =
                "/export/csv/locations?" +
                (this.query.sort ? "sort=" + this.query.sort : "sort=id") +
                (this.query.sortDirection
                    ? "&sortDirection=" + this.query.sortDirection
                    : "&sortDirection=desc") +
                (this.query.s ? "&s=" + this.query.s : "");

            this.exporting = true;

            Axios.get(url, { responseType: "arraybuffer" })
                .then(res => {
                    let url = window.URL.createObjectURL(
                        new Blob([res.data], { type: "application/csv" })
                    );

                    let a = document.createElement("a");
                    a.href = url;
                    a.setAttribute(
                        "download",
                        "Lokalizacje-" + new Date().toLocaleDateString() + ".csv"
                    );
                    document.body.appendChild(a);
                    a.click();

                    this.exporting = false;
                })
                .catch(err => {
                    console.error(err);
                    this.exporting = false;
                });
        }
    }
};
</script>
