<template>
    <div>
        <div class="main-view-container" style="position: relative">
            <div class="main-view-heading">
                <h1>Przekaźniki</h1>
                <div
                    class="main-view-add-item"
                    v-if="$store.getters.getUser.permissions == 'admin'"
                >
                    <router-link class="btn btn-primary" tag="button" to="/transmitters/new">
                        + Dodaj przekaźnik
                    </router-link>
                </div>
            </div>
            <div class="item-list-settings">
                <div class="item-list-settings-group filters">
                    <h4>Filtry</h4>
                    <div class="item-list-settings-line">
                        <span> Szukaj w bazie <i>Przekaźniki</i> </span>
                        <span class="sl-fl1">
                            <input
                                type="text"
                                placeholder="Szukana wartość"
                                v-model="searchTerm"
                                autocomplete="off"
                                @keydown.enter="filter()"
                            />
                            <span class="incorrect-message" :class="{ showed: searchingError }"
                                >To pole nie może być puste</span
                            >
                        </span>
                    </div>
                    <div class="item-list-settings-line">
                        <button class="btn btn-secondary" @click="filter()">
                            Filtruj <i class="fas fa-filter"></i>
                        </button>
                        <button class="btn btn-warning" @click="removeFilter()">
                            Usuń filtry <i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <div class="item-list-settings-group sorting">
                    <h4>Sortowanie</h4>
                    <div class="item-list-settings-line">
                        <span> Sortuj przekaźniki </span>
                        <span class="sl-fl1">
                            <select v-model="sortTerm">
                                <option value="">Wybierz pole</option>
                                <option value="id|desc">Data dodania - malejąco (domyślne)</option>
                                <option value="id|asc">Data dodania - rosnąco</option>
                                <option value="type|desc">Typ przekaźnika - malejąco</option>
                                <option value="type|asc">Typ przekaźnika - rosnąco</option>
                                <option value="location|desc">Lokalizacja - malejąco</option>
                                <option value="location|asc">Lokalizacja - rosnąco</option>
                                <option value="variant|desc">Odmiana - malejąco</option>
                                <option value="variant|asc">Odmiana - rosnąco</option>
                                <option value="serialNumber|desc">Numer seryjny - malejąco</option>
                                <option value="serialNumber|asc">Numer seryjny - rosnąco</option>
                                <!-- <option value="productionDate|desc">Data produkcji - malejąco</option>
                                <option value="productionDate|asc">Data produkcji - rosnąco</option> -->
                                <option value="code|desc">Kod przekaźnika - malejąco</option>
                                <option value="code|asc">Kod przekaźnika - rosnąco</option>
                                <option value="otpCode|desc">Kod OTP - malejąco</option>
                                <option value="otpCode|asc">Kod OTP - rosnąco</option>
                                <option value="otpStatus|desc">Status OTP - malejąco</option>
                                <option value="otpStatus|asc">Status OTP - rosnąco</option>
                            </select>
                            <span class="incorrect-message" :class="{ showed: sortingError }"
                                >To pole nie może być puste</span
                            >
                        </span>
                    </div>
                    <div class="item-list-settings-line">
                        <button class="btn btn-secondary" @click="sortTransmitterss()">
                            Sortuj <i class="fas fa-sort-amount-up"></i>
                        </button>
                        <button class="btn btn-warning" @click="removeSorting()">
                            Wyczyść <i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <div
                    class="item-list-settings-group export"
                    v-if="$store.getters.getUser.permissions != 'automatician'"
                >
                    <h4>Eksport</h4>
                    <div class="item-list-settings-line">
                        <span class="sl-fl1">
                            Eksportuj <i>Przekaźniki</i> do pliku .csv (jeżeli zastosowane zostały
                            filtry, zostaną one uwzględnione podczas eksportu)
                        </span>
                    </div>
                    <div class="item-list-settings-line">
                        <button class="btn btn-secondary" @click="exportData()">
                            Eksportuj
                            <i
                                class="fas"
                                :class="{
                                    'fa-circle-notch fa-spin': exporting,
                                    'fa-file-csv': !exporting
                                }"
                            ></i>
                        </button>
                    </div>
                </div>
            </div>
            <!-- WIDOK TABELI -->
            <router-view :key="$route.fullPath"></router-view>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import Axios from "axios";

export default {
    data() {
        return {
            searchTerm: "",
            searchingError: false,

            sortTerm: "",
            sortingError: false,

            exporting: false
        };
    },
    computed: {
        query() {
            return this.$route.query;
        }
    },
    methods: {
        ...mapActions(["POST"]),
        addTransmitters() {
            this.emptyDistrict = false;
            if (this.district == "") {
                this.emptyDistrict = true;
                return false;
            }

            this.addingInProgress = true;

            this.POST({
                url: "/transmitters",
                data: {
                    transmitters: this.district
                }
            })
                .then(res => {
                    this.addingInProgress = false;
                    this.district = "";
                    this.$store.commit("newSuccess", res.msg);
                    this.$emit("refreshTransmitterss", true);
                })
                .catch(e => {
                    console.error(e);
                });
        },
        filter() {
            this.searchingError = false;
            if (this.searchTerm == "") {
                this.searchingError = true;
                return false;
            }

            this.$router.push({
                query: Object.assign({}, this.$route.query, { s: this.searchTerm }),
                params: {
                    num: 1
                }
            });
        },
        removeFilter() {
            let q = Object.assign({}, this.$route.query);
            delete q.s;
            this.$router.replace({ query: q }).catch(() => {});
            this.searchTerm = "";
        },
        sortTransmitterss() {
            this.sortingError = false;
            if (this.sortTerm == "") {
                this.sortingError = true;
                return false;
            }

            let qa = this.sortTerm.split("|");

            this.$router.push({
                query: Object.assign({}, this.$route.query, { sort: qa[0], sortDirection: qa[1] })
            });
        },
        removeSorting() {
            let q = Object.assign({}, this.$route.query);
            delete q.sort;
            delete q.sortDirection;
            this.$router.replace({ query: q });
        },
        exportData() {
            const url =
                "/export/csv/transmitters?" +
                (this.query.sort ? "sort=" + this.query.sort : "sort=id") +
                (this.query.sortDirection
                    ? "&sortDirection=" + this.query.sortDirection
                    : "&sortDirection=desc") +
                (this.query.s ? "&s=" + this.query.s : "");

            this.exporting = true;

            Axios.get(url, { responseType: "arraybuffer" })
                .then(res => {
                    let url = window.URL.createObjectURL(
                        new Blob([res.data], { type: "application/csv" })
                    );

                    let a = document.createElement("a");
                    a.href = url;
                    a.setAttribute(
                        "download",
                        "Przekaźniki-" + new Date().toLocaleDateString() + ".csv"
                    );
                    document.body.appendChild(a);
                    a.click();

                    this.exporting = false;
                })
                .catch(err => {
                    console.error(err);
                    this.exporting = false;
                });
        }
    },
    created() {
        if (this.query.s && this.query.s != "") {
            this.searchTerm = this.query.s;
        }
        if (this.query.sort && this.query.sort != "") {
            let dir = "asc";
            if (this.query.sortDirection && this.query.sortDirection == "desc") {
                dir = "desc";
            }
            this.sortTerm = this.query.sort + "|" + this.query.sortDirection;
        }
    }
};
</script>
