import Vue from "vue";
import Vuex from "vuex";
import Axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: {
            username: null,
            permissions: null,
            token: null,
            tokenExpDate: null
        },
        messages: {
            successes: [],
            errors: []
        }
    },
    getters: {
        getSuccesses: state => {
            return state.messages.successes;
        },
        getErrors: state => {
            return state.messages.errors;
        },
        getUser: state => {
            return {
                username: state.user.username,
                permissions: state.user.permissions
            };
        },
        isLoggedIn: state => {
            if (state.user.token == null) {
                return "false";
            }
            return "true";
        }
    },
    mutations: {
        newSuccess: (state, data) => {
            state.messages.successes.push(data + "{|}" + Date.now());
        },
        newError: (state, data) => {
            state.messages.errors.push(data + "{|}" + Date.now());
        },
        removeSuccess: (state, index) => {
            state.messages.successes.splice(index, 1);
        },
        removeError: (state, index) => {
            state.messages.errors.splice(index, 1);
        },
        setAuthData: (state, data) => {
            state.user.username = data.login;
            state.user.permissions = data.permissions;
            state.user.token = data.token;
            state.user.tokenExpDate = data.tokenExpDate;
            Axios.defaults.headers.common["Authorization"] = "Bearer " + data.token;
            localStorage.setItem("token", data.token);
            localStorage.setItem("tokenExpDate", data.tokenExpDate);
            localStorage.setItem("login", data.login);
            localStorage.setItem("permissions", data.permissions);
        },
        unsetAuthData: state => {
            state.user.username = null;
            state.user.token = null;
            state.user.permissions = null;
            state.user.tokenExpDate = null;
            localStorage.removeItem("token");
            localStorage.removeItem("tokenExpDate");
            localStorage.removeItem("login");
            localStorage.removeItem("permissions");
            Axios.defaults.headers.common["Authorization"] = null;
        }
    },
    actions: {
        loginFromStorage: ({ commit }, data) => {
            if (localStorage.getItem("token")) {
                var d = new Date(localStorage.getItem("tokenExpDate"));

                if (!d) return false;

                if (d.getTime() > Date.now()) {
                    commit("setAuthData", {
                        token: localStorage.getItem("token"),
                        tokenExpDate: localStorage.getItem("tokenExpDate"),
                        login: localStorage.getItem("login"),
                        permissions: localStorage.getItem("permissions")
                    });
                } else {
                    localStorage.removeItem("token");
                    localStorage.removeItem("tokenExpDate");
                    localStorage.removeItem("login");
                    localStorage.removeItem("permissions");
                }
            }
        },
        GET({ commit }, data) {
            return new Promise((resolve, reject) => {
                let d = {};
                if (data.config) {
                    d = data.config;
                }

                Axios.get(data.url, d)
                    .then(res => {
                        if (res.data.status == "ok") {
                            resolve(res.data);
                        } else {
                            commit("newError", res.data.msg);
                            reject("Błąd! " + res.data.msg);
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            commit(
                                "newError",
                                "Serwer odpowiedział błędem: " + error.response.status
                            );
                            reject("Błąd! " + error.response.status);
                        } else if (error.request) {
                            commit("newError", "Serwer nieosiągalny");
                            reject("Błąd! Serwer nieosiągalny!");
                        } else {
                            commit("newError", "Nieoczekiwany błąd! " + error.message);
                            reject("Błąd! Nieoczekiwany błąd!");
                        }
                    });
            });
        },
        POST({ commit }, data) {
            return new Promise((resolve, reject) => {
                let d = {};
                if (data.config) {
                    d = data.config;
                }

                Axios.post(data.url, data.data, d)
                    .then(res => {
                        if (res.data.status == "ok") {
                            resolve(res.data);
                        } else {
                            commit("newError", res.data.msg);
                            reject("Błąd! " + res.data.msg);
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            commit(
                                "newError",
                                "Serwer odpowiedział błędem: " + error.response.status
                            );
                            reject("Błąd! " + error.response.status);
                        } else if (error.request) {
                            commit("newError", "Serwer nieosiągalny");
                            reject("Błąd! Serwer nieosiągalny!");
                        } else {
                            commit("newError", "Nieoczekiwany błąd! " + error.message);
                            reject("Błąd! Nieoczekiwany błąd!");
                        }
                    });
            });
        },
        DELETE({ commit }, data) {
            return new Promise((resolve, reject) => {
                let d = {};
                if (data.config) {
                    d = data.config;
                }

                Axios.delete(data.url, data.data, d)
                    .then(res => {
                        if (res.data.status == "ok") {
                            resolve(res.data);
                        } else {
                            commit("newError", res.data.msg);
                            reject("Błąd! " + res.data.msg);
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            commit(
                                "newError",
                                "Serwer odpowiedział błędem: " + error.response.status
                            );
                            reject("Błąd! " + error.response.status);
                        } else if (error.request) {
                            commit("newError", "Serwer nieosiągalny");
                            reject("Błąd! Serwer nieosiągalny!");
                        } else {
                            commit("newError", "Nieoczekiwany błąd! " + error.message);
                            reject("Błąd! Nieoczekiwany błąd!");
                        }
                    });
            });
        },
        PATCH({ commit }, data) {
            return new Promise((resolve, reject) => {
                let d = {};
                if (data.config) {
                    d = data.config;
                }

                Axios.patch(data.url, data.data, d)
                    .then(res => {
                        if (res.data.status == "ok") {
                            resolve(res.data);
                        } else {
                            commit("newError", res.data.msg);
                            reject("Błąd! " + res.data.msg);
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            commit(
                                "newError",
                                "Serwer odpowiedział błędem: " + error.response.status
                            );
                            reject("Błąd! " + error.response.status);
                        } else if (error.request) {
                            commit("newError", "Serwer nieosiągalny");
                            reject("Błąd! Serwer nieosiągalny!");
                        } else {
                            commit("newError", "Nieoczekiwany błąd! " + error.message);
                            reject("Błąd! Nieoczekiwany błąd!");
                        }
                    });
            });
        },
        PUT({ commit }, data) {
            return new Promise((resolve, reject) => {
                let d = {};
                if (data.config) {
                    d = data.config;
                }

                Axios.put(data.url, data.data, d)
                    .then(res => {
                        if (res.data.status == "ok") {
                            resolve(res.data);
                        } else {
                            commit("newError", res.data.msg);
                            reject("Błąd! " + res.data.msg);
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            commit(
                                "newError",
                                "Serwer odpowiedział błędem: " + error.response.status
                            );
                            reject("Błąd! " + error.response.status);
                        } else if (error.request) {
                            commit("newError", "Serwer nieosiągalny");
                            reject("Błąd! Serwer nieosiągalny!");
                        } else {
                            commit("newError", "Nieoczekiwany błąd! " + error.message);
                            reject("Błąd! Nieoczekiwany błąd!");
                        }
                    });
            });
        }
    }
});
