<template>
    <div class="main-view-container" style="position: relative;">
        <div class="main-view-heading" style="padding: 20px 15px; margin-bottom: 0;">
            <h1 style="display: flex; align-items: center; margin: 0;">
                <button tag="button" class="btn btn-secondary" style="margin-right: 10px;" @click="$router.push('/transmitters/' + $route.params.code + '/change-status')">
                    <i class="fas fa-arrow-left"></i> Powrót
                </button>
                Zmiana statusu stojak-zapas/zapas-stojak
            </h1>
        </div>
        <transition name="fade" mode="out-in">
            <jsk-spinner :transparent="true" :static="true" v-if="loading">Pobieranie danych</jsk-spinner>
            <div class="main-view-content-container" style="padding:30px 15px; justify-content: flex-start" v-else>
                <div class="col-1-3">
                    <div class="cs-i-heading">
                        Przekaźnik 1
                    </div>
                    <div class="cs-i-group">
                        <div class="cs-i-group-name">
                            Kod przekaźnika
                        </div>
                        <div class="cs-i-group-value">
                            {{transmitterA.transmitterCode}}
                        </div>
                    </div>
                    <div class="cs-i-group">
                        <div class="cs-i-group-name">
                            Kod OTP przekaźnika
                        </div>
                        <div class="cs-i-group-value">
                            {{transmitterA.otpCode}}
                        </div>
                    </div>
                    <div class="cs-i-group">
                        <div class="cs-i-group-name">
                            Odmiana przekaźnika
                        </div>
                        <div class="cs-i-group-value">
                            {{transmitterA.variant}}
                        </div>
                    </div>
                    <div class="cs-i-group">
                        <div class="cs-i-group-name">
                            Status
                        </div>
                        <div class="cs-i-group-value">
                            {{transmitterA.status}}
                        </div>
                    </div>
                    <div class="cs-i-group">
                        <div class="cs-i-group-name">
                            Lokalizacja
                        </div>
                        <div class="cs-i-group-value">
                            {{transmitterA.cache.location}}
                        </div>
                    </div>
                    <div class="cs-i-group">
                        <div class="cs-i-group-name">
                            Stojak
                        </div>
                        <div class="cs-i-group-value">
                            {{transmitterA.stand}}
                        </div>
                    </div>
                </div>
                <div class="col-1-3">
                    <div class="cs-i-heading">
                        Przekaźnik 2
                    </div>
                    <transition name="fade">
                        <div v-if="transmitterB.transmitterCode">
                            <div class="cs-i-group">
                                <div class="cs-i-group-name">
                                    Kod przekaźnika
                                </div>
                                <div class="cs-i-group-value">
                                    {{transmitterB.transmitterCode}}
                                </div>
                            </div>
                            <div class="cs-i-group">
                                <div class="cs-i-group-name">
                                    Kod OTP przekaźnika
                                </div>
                                <div class="cs-i-group-value">
                                    {{transmitterB.otpCode}}
                                </div>
                            </div>
                            <div class="cs-i-group">
                                <div class="cs-i-group-name">
                                    Odmiana przekaźnika
                                </div>
                                <div class="cs-i-group-value">
                                    {{transmitterB.variant}}
                                </div>
                            </div>
                            <div class="cs-i-group">
                                <div class="cs-i-group-name">
                                    Status
                                </div>
                                <div class="cs-i-group-value">
                                    {{transmitterB.status}}
                                </div>
                            </div>
                            <div class="cs-i-group">
                                <div class="cs-i-group-name">
                                    Lokalizacja
                                </div>
                                <div class="cs-i-group-value">
                                    {{transmitterB.cache.location}}
                                </div>
                            </div>
                        </div>
                        <jsk-spinner :transparent="true" :static="true" v-else-if="loadingB">Pobieranie daych</jsk-spinner>
                        <div v-else style="padding: 30px 0;">
                            Proszę wyszukać drugi przekaźnik
                        </div>
                    </transition>
                    <div style="margin-top: 20px; padding-top: 10px; border-top: 1px solid #ddd;">
                        <p style="font-weight: 600">Wyszukaj przekaźnik 2</p>
                        <div class="material-input" ref="tbinput">
                            <input type="text" v-model="TBcode" class="material-input-field" @blur="handleBlur($event)" autocomplete="off">
                            <label for="login" class="material-input-label">Kod przekaźnika lub Kod OTP</label>
                            <div class="material-input-border"></div>
                            <div class="material-input-error-msg">
                                Wypełnij to pole
                            </div>
                        </div>
                        <div style="margin-top: 5px;">
                            <button class="btn btn-primary" style="width: 100%; text-align: center;" @click="getTB()">
                                <i class="fas fa-search"></i> Wyszukaj
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-1-3" style="display: flex; align-items: flex-start; justify-content: center">
                    <transition name="fade">
                        <div v-if="transmitterB.transmitterCode" style="padding: 0 15px;">
                            <p style="font-weight: 600;">
                                Zatwierdź zmianę
                            </p>
                            <button class="btn btn-primary" @click="swapStatuses()" style="padding: 30px 15px">
                                <i class="fas fa-exchange-alt"></i> Zamień statusy przekaźników
                            </button>
                        </div>
                    </transition>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import jskSpinner from '../components/Spinner.vue';
import {ModelListSelect} from 'vue-search-select';

export default {
    data(){
        return{
            loading: false,
            loadingB: false,

            transmitterA: {},
            transmitterB: {},

            TBcode: '',

            locationError: false
        }
    },
    components: {
        jskSpinner,
        ModelListSelect
    },
    methods: {
        ...mapActions([
            "GET",
            "POST"
        ]),
        handleBlur(ev) {
            if(ev.srcElement.value == ""){
                ev.srcElement.parentNode.classList.remove('wc');
            }else{
                ev.srcElement.parentNode.classList.add('wc');
            }
        },
        getTB(){
            this.transmitterB = {};
            this.$refs.tbinput.classList.remove('incorrect');

            if(this.TBcode == ''){
                this.$refs.tbinput.classList.add('incorrect');
                return false;
            }

            this.loadingB = true;

            this.GET({
                url: '/transmitters/' + this.TBcode
            })
            .then(res => {
                this.TBcode = "";
                this.$refs.tbinput.blur();
                this.$refs.tbinput.classList.remove('wc');
                this.transmitterB = res.data.transmitter;
                if(this.transmitterB.transmitterCode == this.transmitterA.transmitterCode){
                    this.transmitterB = {};
                    this.$store.commit('newError', "Nie można wybrać przekaźnika nr 1 jako przekaźnik nr 2!")
                }
                this.loadingB = false;
            })
            .catch(e => {
                this.loadingB = false;
                console.error(e);
            })
        },
        swapStatuses(){
            if(!this.transmitterA.otpCode || !this.transmitterB.otpCode) return false;
            this.loading = true;

            let d = {};

            if(this.transmitterA.status == 'stojak'){
                d.codeA = this.transmitterA.transmitterCode;
                d.otpCodeB = this.transmitterB.otpCode;
            }else{
                d.codeA = this.transmitterB.transmitterCode;
                d.otpCodeB = this.transmitterA.otpCode;
            }

            this.POST({
                url: "/transmitters/changeStatus/from/zapas/to/stojak",
                data: d
            })
            .then(res => {
                this.$store.commit('newSuccess', res.msg);
                this.loading = false;
                this.$router.push("/transmitters/" + this.transmitterA.transmitterCode + '/details');
            })
            .catch(e => {
                this.loading = false;
                console.error(e);
            });
        }
    },
    created(){
        this.loading = true;

        this.GET({
            url: '/transmitters/' + this.$route.params.code
        })
        .then(res => {
            this.transmitterA = res.data.transmitter;
            this.loading = false;
        })
        .catch(e => {
            console.error(e);
        })
    }
}
</script>
