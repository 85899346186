<template>
    <div>
        <transition name="slide" appear>
            <header v-if="isLoggedIn == 'true'">
                <div class="wrapper">
                    <div class="header">
                        <div class="header-brand">
                            <router-link to="/" tag="div">
                                <img src="@/assets/logo.png" alt="" />
                            </router-link>
                            <div class="header-brand-name">
                                <div>Ewidencja</div>
                                <div>Przekaźników</div>
                            </div>
                        </div>

                        <div class="header-user">
                            <div class="header-user-username">
                                <i class="fas fa-user"></i>
                                {{
                                    user.username.split("")[0].toUpperCase() +
                                    user.username.slice(1)
                                }}
                            </div>
                            <router-link to="/account" class="btn btn-outline-primary">
                                Moje konto
                            </router-link>
                            <button class="btn btn-danger" @click="logout()">WYLOGUJ</button>
                        </div>
                    </div>
                </div>
            </header>
        </transition>
        <main>
            <transition name="slide-right" appear>
                <div class="menubar" v-if="isLoggedIn == 'true'">
                    <div class="wrapper">
                        <div class="menubar-group">
                            <div class="menubar-group-heading">
                                <i class="fas fa-database"></i> Bazy danych
                            </div>
                            <ul class="menubar-group-list">
                                <router-link
                                    to="/transmitters"
                                    class="menubar-group-list-item"
                                    active-class="active"
                                    tag="li"
                                >
                                    Przekaźniki
                                </router-link>
                                <router-link
                                    to="/transmitters-types"
                                    class="menubar-group-list-item"
                                    active-class="active"
                                    tag="li"
                                >
                                    Typy przekaźników
                                </router-link>
                                <router-link
                                    to="/locations"
                                    class="menubar-group-list-item"
                                    active-class="active"
                                    tag="li"
                                >
                                    Lokalizacja
                                </router-link>
                                <router-link
                                    to="/users"
                                    class="menubar-group-list-item"
                                    active-class="active"
                                    tag="li"
                                    v-if="user.permissions == 'admin'"
                                >
                                    Użytkownicy
                                </router-link>
                            </ul>
                        </div>
                        <!-- <div class="menubar-group">
                            <div class="menubar-group-heading">
                                <i class="fas fa-cogs"></i> Ustawienia
                            </div>
                        </div> -->
                    </div>
                </div>
            </transition>

            <div class="app-alerts">
                <transition-group name="table" tag="div">
                    <div
                        class="app-alert-error"
                        v-for="(error, index) in appErrors"
                        :key="error.split('{|}')[1]"
                        @click="removeError(index)"
                        title="Naciśnij aby zamknąć"
                    >
                        {{ error.split("{|}")[0] }}
                    </div>
                </transition-group>
                <transition-group name="table" tag="div">
                    <div
                        class="app-alert-success"
                        v-for="(succ, index) in appSuccesses"
                        :key="succ.split('{|}')[1]"
                        @click="removeSuccess(index)"
                        title="Naciśnij aby zamknąć"
                    >
                        {{ succ.split("{|}")[0] }}
                    </div>
                </transition-group>
            </div>

            <div class="main-view">
                <transition name="slide" appear mode="out-in">
                    <router-view></router-view>
                </transition>
            </div>
        </main>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import Axios from "axios";

export default {
    data() {
        return {
            username: "",
            password: ""
        };
    },
    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        isLoggedIn() {
            return this.$store.getters.isLoggedIn;
        },
        appErrors() {
            return this.$store.getters.getErrors;
        },
        appSuccesses() {
            return this.$store.getters.getSuccesses;
        }
    },
    methods: {
        ...mapActions(["POST"]),
        logout() {
            this.$store.commit("unsetAuthData");
            this.$store.state.messages.successes = [];
            this.$store.state.messages.errors = [];
            this.$router.push("/login");
        },
        login() {},
        removeError(index) {
            this.$store.commit("removeError", index);
        },
        removeSuccess(index) {
            this.$store.commit("removeSuccess", index);
        }
    },
    created() {
        window.addEventListener("click", function (e) {
            if (e.srcElement.classList.contains("material-input-label")) {
                e.srcElement.previousElementSibling.focus();
            }
        });
    }
};
</script>

<style lang="scss">
$primary: #27ae60;
$secondary: #2980b9;
$danger: #e74c3c;
$warning: #f1c40f;
$bg-light: #ecf0f1;
$b-radius: 3px;
$text-black: #444;

@import url("https://fonts.googleapis.com/css?family=Raleway:400,600");

* {
    box-sizing: border-box;
}
html {
    font-family: "Raleway", sans-serif;
    font-size: 15px;
    color: $text-black;
}
body {
    margin: 0;
    background-color: $bg-light;
    min-width: 1000px;
    overflow-x: hidden;
    overflow-y: scroll;
}
.wrapper {
    padding: 0 15px;
}
a {
    text-decoration: none;
    color: inherit;
}
::-webkit-scrollbar {
    width: 6px;
}
::-webkit-scrollbar-track {
    background: darken($bg-light, 5%);
}
::-webkit-scrollbar-thumb {
    background: $primary;
    border-radius: 3px;
}

.bg {
    &-primary {
        background-color: $primary;
    }
    &-warning {
        background-color: $warning;
    }
    &-danger {
        background-color: $danger;
    }
}

header {
    background-color: #fff;
    padding: 10px 0;
    box-shadow: 0px 1px 1px 0 rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 998;
    min-width: 800px;

    & + * {
        margin-top: 78px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-brand {
            display: flex;
            align-items: center;

            img {
                width: 150px;
                cursor: pointer;
            }

            &-name {
                text-transform: uppercase;
                font-size: 18px;
                padding-left: 5px;
            }
        }

        &-user {
            display: flex;
            align-items: center;
            padding-right: 15px;

            & > * {
                margin-left: 15px;
            }
        }
    }
}

.main-view {
    width: calc(100% - 200px);
    margin-left: 200px;
    padding: 30px;

    &-content-container {
        border-top: 1px solid #ddd;
        padding: 30px 15px 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        h2 {
            margin-bottom: 30px;
            margin-top: 0;
            font-size: 18px;
            font-weight: 400;
            position: relative;
            padding-bottom: 5px;

            &:after {
                position: absolute;
                content: "";
                height: 2px;
                width: 40px;
                background-color: $primary;
                top: 100%;
                left: 0;
            }
        }

        .col-1-2 {
            width: calc(50% - 15px);
            margin-bottom: 30px;
        }
        .col-1-3 {
            width: calc(33.33% - 20px);
            margin-bottom: 30px;
        }
        .col-2-3 {
            width: calc(66.66% - 20px);
            margin-bottom: 30px;
        }
        .col {
            width: 100%;
            margin-bottom: 30px;
        }
    }
}

.menubar {
    position: fixed;
    width: 200px;
    height: calc(100vh - 78px);
    background-color: #fff;
    box-shadow: 1px 0 1px 0 rgba(0, 0, 0, 0.3);
    z-index: 997;
    padding: 30px 0;

    &-group {
        margin-bottom: 15px;

        &-heading {
            font-size: 17px;
            i {
                margin-right: 5px;
            }
        }

        &-list {
            margin: 0;
            padding: 0;
            list-style: none;

            margin-top: 10px;
            padding-left: 25px;

            &-item {
                padding: 5px 0;
                cursor: pointer;
                position: relative;

                &:before {
                    content: "";
                    width: 8px;
                    height: 8px;
                    background-color: $text-black;
                    border-radius: 3px;
                    position: absolute;
                    left: -15px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:hover,
                &.active {
                    color: $primary;
                }

                &:hover:before,
                &.active:before {
                    background-color: $primary;
                }
            }
        }
    }
}

.app-alerts {
    margin-left: 200px;
    padding: 15px 30px 0;
    width: calc(100% - 200px);
    // position: fixed;
    // z-index: 99;
    // left: 0;
    // top: 78px;

    .app-alert-error,
    .app-alert-success {
        padding: 10px 15px;
        border-radius: 3px;
        color: #fff;
        cursor: pointer;
        margin-bottom: 10px;
    }

    .app-alert-error {
        background-color: $danger;
        box-shadow: 1px 1px 1px 0 rgba($danger, 0.6);
    }

    .app-alert-success {
        background-color: $primary;
        box-shadow: 1px 1px 1px 0 rgba($primary, 0.6);
    }
}

.btn {
    display: inline-block;
    padding: 10px 15px;
    outline: none;
    background: none;
    transition: all 0.2s ease;
    border: none;
    border-radius: $b-radius;
    font-family: inherit;
    cursor: pointer;

    &.btn-large {
        font-size: 17px;
    }

    &.btn-sm {
        padding: 5px 10px;
    }

    &.btn-danger {
        background-color: $danger;
        color: #fff;
        box-shadow: 1px 1px 1px 0 rgba($danger, 0.5);
        border: 1px solid $danger;
        &:hover {
            background-color: darken($danger, 10%);
        }
    }
    &.btn-primary {
        background-color: $primary;
        color: #fff;
        box-shadow: 1px 1px 1px 0 rgba($primary, 0.5);
        border: 1px solid $primary;
        &:hover {
            background-color: darken($primary, 10%);
        }
    }
    &.btn-secondary {
        background-color: $secondary;
        color: #fff;
        box-shadow: 1px 1px 1px 0 rgba($secondary, 0.5);
        border: 1px solid $secondary;
        &:hover {
            background-color: darken($secondary, 10%);
        }
    }
    &.btn-warning {
        background-color: $warning;
        color: #fff;
        border: 1px solid $warning;
        box-shadow: 1px 1px 1px 0 rgba($warning, 0.5);
        &:hover {
            background-color: darken($warning, 10%);
        }
    }
    &.btn-outline-primary {
        background: none;
        color: $primary;
        border: 1px solid $primary;
        &:hover {
            background-color: $primary;
            color: #fff;
            box-shadow: 1px 1px 1px 0 rgba($primary, 0.5);
        }
    }
}

.material-input {
    //margin-bottom: 15px;
    position: relative;

    input[type="text"],
    input[type="password"] {
        border: none;
        border-bottom: 1px solid #707070;
        background: transparent;
        width: 100%;
        display: inline-block;
        outline: none;
        color: #444;
        font-size: 15px;
        padding: 10px 2px 7px;
        margin-bottom: 15px;
        position: relative;

        &:focus + label {
            top: -6px;
            font-size: 11px;
            color: $primary;
        }

        &:focus ~ .material-input-border {
            width: 100%;
            left: 0;
        }
    }

    &.incorrect {
        .material-input-error-msg {
            opacity: 1;
            display: block;
        }
        input[type="text"],
        input[type="password"] {
            & + label {
                color: $danger !important;
                top: -6px;
                font-size: 11px;
            }

            & ~ .material-input-border {
                background-color: $danger !important;
                width: 100%;
                left: 0;
            }
        }

        select {
            border-color: $danger;
            color: $danger;
        }
    }

    select {
        display: block;
        border-radius: 3px;
        padding: 10px;
        width: 100%;
        text-align: center;
        border: 1px solid #999;

        &:active,
        &:focus {
            outline: none;
            border-color: $primary;
        }

        & + .material-input-error-msg {
            top: 2px;
        }
    }

    &.wc {
        input[type="text"],
        input[type="password"] {
            & + label {
                top: -6px;
                font-size: 11px;
                color: $primary;
            }

            & ~ .material-input-border {
                background-color: $primary;
                width: 100%;
                left: 0;
            }
        }
    }

    &-error-msg {
        position: relative;
        top: -15px;
        font-size: 13px;
        color: $danger;
        opacity: 0;
        transition: all 0.3s ease;
        transition-delay: 0.1s;
        text-align: left;
        display: none;
    }

    &-border {
        position: relative;
        top: -17px;
        width: 0;
        left: 50%;
        height: 2px;
        transition: all 0.2s ease;
        background-color: $primary;
    }

    label {
        position: absolute;
        left: 2px;
        top: 10px;
        color: #707070;
        font-size: 15px;
        transition: all 0.2s ease;
        cursor: text;
    }
}

//animacje
.slide-leave-to,
.slide-enter {
    opacity: 0;
    transform: translateY(-40px);
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.75s ease;
}

.slide-right-leave-to,
.slide-right-enter {
    opacity: 0;
    transform: translateX(-40px);
}

.slide-right-enter-active,
.slide-right-leave-active {
    transition: all 0.75s ease;
}

.fade-leave-to,
.fade-enter {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.5s ease;
}

.table-enter-to {
    opacity: 1;
}

.table-leave-to,
.table-enter {
    opacity: 0;
}

.table-enter-active,
.table-leave-active {
    transition: all 0.6s ease;
}

.table-move {
    transition: all 0.6s ease;
}

@keyframes fadeDown {
    from {
        transform: translateY(-20%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

//ogólnie podstrona z danymi (pasek górny)
.main-view {
    &-container {
        border: 1px solid #ccc;
        border-radius: 3px;
        background-color: #fff;
        box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.3);
        //padding: 15px;
    }

    &-heading {
        padding: 15px 15px 0;

        display: flex;
        justify-content: space-between;

        h1 {
            margin-top: 0;
            font-weight: 400;
            font-size: 32px;
            margin-right: 10px;
        }
        margin-bottom: 20px;
    }
}

.flat-input-group {
    display: inline-block;
    max-width: 200px;
    position: relative;
    margin-right: 5px;

    input {
        display: inline-block;
        padding: 9px 14px;
        font-size: 15px;
        color: #444;
        border: 1px solid #999;
        border-radius: 3px;
        transition: all 0.2s ease;
        outline: none;
        max-width: 100%;

        &:active,
        &:focus {
            border-color: $primary;
            box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.3);
        }
    }

    .flat-input-incorrect-message {
        position: absolute;
        left: 0;
        top: 100%;
        font-size: 12px;
        color: $danger;
        width: 100%;
        margin-top: 2px;
        transition: all 0.3s ease;
        transition-delay: 0.1s;
        display: none;
        opacity: 0;
    }

    &.incorrect .flat-input-incorrect-message {
        display: block;
        opacity: 1;
    }
}

.item-list-settings {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;

    display: flex;

    &-group {
        width: 33.33%;
        padding: 20px 15px;

        h4 {
            margin-top: 0;
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 15px;
        }

        &:nth-child(2) {
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
        }
    }

    &-line {
        display: flex;
        flex-wrap: wrap;

        & > span {
            width: 100%;

            &:not(:last-child) {
                margin-bottom: 5px;
            }

            select,
            input {
                display: inline-block;
                padding: 10px 15px;
                border: 1px solid #999;
                border-radius: 3px;
                width: 100%;
                outline: none;
                transition: all 0.2s ease;

                &:focus,
                &:active {
                    border-color: $primary;
                    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
                }
            }
        }

        .sl-fl1 {
            position: relative;

            .incorrect-message {
                color: $danger;
                font-size: 12px;
                opacity: 0;
                display: none;
                transition: all 0.3s ease;
                transition-delay: 0.1s;

                &.showed {
                    display: block;
                    opacity: 1;
                }
            }
        }

        &:not(:last-child) {
            margin-bottom: 15px;
        }

        button {
            margin-right: 10px;
        }
    }
}

.itemlist {
    &-container {
        padding: 15px;

        h2 {
            font-weight: 400;
        }

        .item-pages {
            float: right;
            margin-left: 10px;
            font-size: 13px;
            color: #777;
        }

        table {
            width: 100%;
            border-collapse: collapse;

            th {
                border-right: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
                padding: 10px;
                text-align: left;
                font-size: 15px;

                &:last-child {
                    border-right: none;
                }
            }

            td {
                padding: 5px 10px;

                &:first-child {
                    font-weight: 600;
                }

                &.center-cell {
                    text-align: center;
                }

                .status {
                    display: inline-block;
                    padding: 5px;
                    border-radius: 3px;

                    &-ok {
                        background-color: $primary;
                        color: #fff;
                    }

                    &-nok {
                        background-color: $danger;
                        color: #fff;
                    }

                    &-ok3 {
                        background-color: $warning;
                        color: #fff;
                    }
                }
            }

            .tr-center {
                td,
                th {
                    text-align: center;
                }
            }

            tbody tr:nth-child(odd) {
                background-color: #ecf0f1;
            }
        }
    }
}

.list-navigation {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;

    button {
        margin-left: 10px;
    }

    .pag-group {
        margin-left: 30px;
    }
}

.jsk-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(#999, 0.6);
    z-index: 999;

    &-window {
        max-width: 600px;
        background-color: #fff;
        border-radius: 3px;
        border: 1px solid #bbb;
        margin: 100px auto 0;
        padding: 20px 15px;
    }

    &-header {
        font-size: 26px;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ddd;
    }

    &-body {
        padding: 15px 0 25px;
        border-bottom: 1px solid #ddd;
        margin-bottom: 10px;
    }

    &-footer {
        text-align: right;
        button {
            margin-left: 10px;
        }
    }
}

.cs-i-heading {
    font-size: 17px;
    margin-bottom: 25px;
    position: relative;
    padding-bottom: 3px;

    &:after {
        content: "";
        width: 50px;
        height: 2px;
        background-color: #27ae60;
        position: absolute;
        top: 100%;
        left: 0;
    }
}

.cs-i-group {
    margin-bottom: 15px;

    &-name {
        font-weight: 600;
        margin-bottom: 5px;
    }
}

.fradio {
    &__container {
        display: flex;
        flex-wrap: wrap;
        // padding-bottom: 15px;
    }

    &__button {
        padding: 5px 8px;
        border-radius: 2px;
        border: none;
        background-color: $text-black;
        color: #fff;
        transition: all 0.2s ease;
        font-size: inherit;
        font-family: inherit;
        margin: 0 5px 5px 0;
        cursor: pointer;

        &:hover {
            background-color: lighten($text-black, 10%);
        }

        &.active {
            background-color: $secondary;

            &:hover {
                background-color: darken($secondary, 5%);
            }
        }
    }
}
</style>
