<template>
    <div class="itemlist-container">
        <transition name="fade" appear mode="out-in">
            <div v-if="loading">
                <jsk-spinner :transparent="true" :static="true">
                    Ładowanie typów przekaźników
                </jsk-spinner>
            </div>
            <div v-else>
                <h2>
                    Wyniki wyszukiwania
                    <span class="item-pages">Strona {{page}} z {{pages}}</span>
                </h2>
                <table class="tb1">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Typ przekaźnika</th>
                            <th>Akcje</th>
                            
                        </tr>
                    </thead>
                    <transition-group name="table" tag="tbody">
                        <tr v-for="(transmitterstype, index) in transmitters" :key="transmitterstype._id">
                            <td class="n1">
                              {{(page - 1) * 25 + (index + 1)}} 
                            </td>
                            <td>
                               {{transmitterstype.transmitterType}}
                            </td>
                            <td>
                                <button 
                                    class="btn btn-danger" 
                                    @click="openModal( transmitterstype._id)"
                                    title="Usuń przekaźnik"
                                    v-if="$store.getters.getUser.permissions == 'admin'"
                                >
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                                <div style="width: 30px; height: 40px" v-if="$store.getters.getUser.permissions != 'admin'"></div>
                            </td>   
                        </tr>
                    </transition-group>
                    <tr v-if="transmitters.length == 0" key="noResults">
                        <td colspan="3">Brak wyników wyszukiwania</td>
                    </tr>
                </table>
                <div class="list-navigation" v-if="pages > 1">
                    <div class="jump-group">
                        <router-link 
                            tag="button" 
                            :to="{path: '/transmitters-types/1', query }" 
                            class="btn btn-secondary"
                            v-if="page > 1"
                        >
                            Pierwsza strona
                        </router-link>
                        <router-link 
                            class="btn btn-secondary" 
                            :to="{path: '/transmitters-types/' + pages, query }" 
                            tag="button"
                            v-if="page < pages"
                        >
                            Ostatnia strona
                        </router-link>
                    </div>
                    <div class="pag-group">
                        <router-link 
                            tag="button" 
                            class="btn btn-secondary" 
                            :to="{path: '/transmitters-types/' + (parseInt(page) - 1), query }" 
                            v-if="page > 1"
                        >
                            Poprzednia strona
                        </router-link>
                        <router-link 
                            tag="button" 
                            class="btn btn-secondary" 
                            :to="{path: '/transmitters-types/' + (parseInt(page) + 1), query }" 
                            v-if="page < pages"
                        >
                            Następna strona
                        </router-link>
                    </div>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div class="jsk-modal" v-if="transmittersRemoveModal" @click="transmittersRemoveModal = false">
                <transition appear name="slide">
                    <div class="jsk-modal-window" @click.stop>
                        <div class="jsk-modal-header">
                            Potwierdzenie usunięcia typu przekaźnika
                        </div>
                        <div class="jsk-modal-body">
                            Czy na pewno usunąć typ przekaźnika {{transmittersToRemove}}? 
                        </div>
                        <div class="jsk-modal-footer">
                            <button class="btn btn-secondary" @click="transmittersRemoveModal = false"><i class="fas fa-ban"></i> Anuluj</button>
                            <button class="btn btn-danger" @click="removeTransmitters(transmittersToRemoveID)"><i class="fas fa-trash-alt"></i> Usuń typ przekaźnika</button>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import jskSpinner from '../components/Spinner.vue' 

export default {
    data(){
        return{
            loading: false,
            transmitters: [],

            pages: 0,

            newTransmitters: "",
            newTransmittersError: false,

            transmittersRemoveModal: false,
            transmittersToRemove: "",
            transmittersToRemoveID: ""
        }
    },
    computed: {
        page(){
            return this.$route.params.num;
        },
        query(){
            return this.$route.query;
        }
    },  
    methods: {
        ...mapActions([
            'GET',
            'POST',
            'DELETE'
        ]),
        fetchData(spinner){
            if(spinner) this.loading = true;
            
            const url = "/transmitters-types?page=" + this.$route.params.num + ((this.query.s)? "&s=" + this.query.s : "") + ((this.query.sort)? "&sort=" + this.query.sort : "&sort=id") + ((this.query.sortDirection)? "&sortDirection=" + this.query.sortDirection : "&sortDirection=desc");

            //console.log(url)
            this.GET({
                url
            })
            .then(res => {
                this.transmitters = res.data.transmittersTypes;
                this.pages = res.data.pages;
                if(spinner) this.loading = false;
            })
            .catch(e => {
                console.error(e);
            });
        },
        removeTransmitters(id){
            this.DELETE({
                url: '/transmitters-types/' + id
            })
            .then(res => {
                this.transmitters.splice(this.transmitters.findIndex(item => {
                    return item._id == id;
                }), 1);
                this.$store.commit('newSuccess', res.msg);
                this.transmittersRemoveModal = false;
                this.transmittersToRemove = "";
                this.transmittersToRemoveID = "";
            })
            .catch(e => {
                console.error(e);
            })
        },
        openModal(id, name){
            this.transmittersRemoveModal = true;
            this.transmittersToRemove = name;
            this.transmittersToRemoveID = id;
        }
    },
    components: {
        jskSpinner
    },
    created(){
        this.fetchData(true);
        this.$parent.$on('refreshTransmitters', () => {
            this.fetchData(false)
        });

    }
}
</script>

<style lang="scss">
    
</style>