<template>
    <div class="jsk-spinner-container" :class="{'jsk-spinner-container-static': static ,'jsk-spinner-container-transparent': transparent}">
        <div class="jsk-spinner-group">
            <div class="jsk-spinner-spinner"></div>
            <div class="jsk-spinner-text">
                <slot/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['static', 'transparent']
}
</script>

<style lang="scss">
.jsk-spinner{
    
    &-container{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 100vh;
        background-color: rgba(#777, .8);
        padding: 50px 0;
    }

    &-container-static{
        position: relative !important;
        height: auto;
    }

    &-container-transparent{
        background-color: transparent !important;

        .jsk-spinner-text{
            color: #444 !important;
        }
    }

    &-group{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &-spinner{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 5px solid rgba(#ddd, .3);
        margin-bottom: 5px;
        margin-left: auto;
        margin-right: auto;
        position: relative;

        &:after{
            position: absolute;
            top: -5px;
            left: -5px;
            width: 100%;
            height: 100%;
            content: "";
            border-radius: 50%;
            border: 5px solid #27AE60;
            border-left: 5px solid transparent;
            animation: jsk-spin 1s linear infinite;
        }
    }

    &-text{
        text-align: center;
        color: #fff;
    }

}

@keyframes jsk-spin{
    from{
        transform: rotate(0);
    }
    to{
        transform: rotate(360deg);
    }
}
</style>
