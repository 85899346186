<template>
    <div class="itemlist-container">
        <transition name="fade" appear mode="out-in">
            <div v-if="loading">
                <jsk-spinner :transparent="true" :static="true">
                    Ładowanie przekaźników
                </jsk-spinner>
            </div>
            <div v-else>
                <h2>
                    Wyniki wyszukiwania
                    <span class="item-pages">Strona {{page}} z {{pages}}</span>
                </h2>
                <table class="tb1">
                    <thead>
                        <tr class="tr-center">
                            <th>#</th>
                            <th>Typ</th>
                            <th>Odmiana</th>
                            <th>Numer seryjny</th>
                            <th>Kod OTP</th>
                            <th>Kod przekaźnika</th>
                            <th>Status OTP</th>
                            <th>Status</th>
                            <th>Akcje</th>
                        </tr>
                    </thead>
                    <transition-group name="table" tag="tbody">
                        <tr v-for="(t, index) in transmitters" :key="t._id" class="tr-center">
                            <td class="n1">
                                {{(page - 1) * 25 + (index + 1)}}
                            </td>
                            <td>
                                {{t.cache.transmitterType}}
                            </td>
                            <td>
                                {{t.variant}}
                            </td>
                            <td>
                                {{t.serialNumber}}
                            </td>
                            <td>
                                {{t.otpCode}}
                            </td>
                            <td>
                                {{t.transmitterCode}}
                            </td>
                            <td>
                                <span style="padding: 5px 10px; border-radius: 3px; color: #fff;" :class="{'bg-primary': (t.otpStatus == 'OK'), 'bg-warning': (t.otpStatus == 'OK+3'), 'bg-danger': (t.otpStatus == 'NOK')}">
                                    {{t.otpStatus}}
                                </span>
                            </td>
                            <td>
                                {{(t.status == 'stojak')? t.cache.location + "/" + t.stand : (t.status == 'zapas')? t.cache.location : (t.status == 'otp')? "OTP" : "Magazyn"}}
                            </td>
                            <td>
                                <router-link class="btn btn-secondary" tag="button" title="Zobacz szczegóły" :to="'/transmitters/' + t.transmitterCode + '/details'">
                                    Zobacz szczegóły
                                    <i class="fas fa-search"></i>
                                </router-link>
                            </td>
                        </tr>
                    </transition-group>
                    <tr v-if="transmitters.length == 0" key="noResults">
                        <td colspan="3">Brak wyników wyszukiwania</td>
                    </tr>
                </table>
                <div class="list-navigation" v-if="pages > 1">
                    <div class="jump-group">
                        <router-link tag="button" :to="{path: '/transmitters/1', query }" class="btn btn-secondary"
                            v-if="page > 1">
                            Pierwsza strona
                        </router-link>
                        <router-link class="btn btn-secondary" :to="{path: '/transmitters/' + pages, query }" tag="button"
                            v-if="page < pages">
                            Ostatnia strona
                        </router-link>
                    </div>
                    <div class="pag-group">
                        <router-link tag="button" class="btn btn-secondary" :to="{path: '/transmitters/' + (parseInt(page) - 1), query }"
                            v-if="page > 1">
                            Poprzednia strona
                        </router-link>
                        <router-link tag="button" class="btn btn-secondary" :to="{path: '/transmitters/' + (parseInt(page) + 1), query }"
                            v-if="page < pages">
                            Następna strona
                        </router-link>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import jskSpinner from '../components/Spinner.vue' 

export default {
    data(){
        return{
            loading: false,
            transmitters: [],

            pages: 0
        }
    },
    computed: {
        page(){
            return this.$route.params.num;
        },
        query(){
            return this.$route.query;
        }
    },  
    methods: {
        ...mapActions([
            'GET',
            'POST',
            'DELETE'
        ]),
        fetchData(spinner){
            if(spinner) this.loading = true;
            
            const url = "/transmitters?page=" + this.$route.params.num + ((this.query.s)? "&s=" + this.query.s : "") + ((this.query.sort)? "&sort=" + this.query.sort : "&sort=id") + ((this.query.sortDirection)? "&sortDirection=" + this.query.sortDirection : "&sortDirection=desc");

            //console.log(url)
            this.GET({
                url
            })
            .then(res => {
                this.transmitters = res.data.transmitters;
                this.pages = res.data.pages;
                if(spinner) this.loading = false;
            })
            .catch(e => {
                console.error(e);
            });
        },
        removeTransmitters(id){
            this.DELETE({
                url: '/transmitters-types/' + id
            })
            .then(res => {
                this.transmitters.splice(this.transmitters.findIndex(item => {
                    return item._id == id;
                }), 1);
                this.$store.commit('newSuccess', res.msg);
                this.transmittersRemoveModal = false;
                this.transmittersToRemove = "";
                this.transmittersToRemoveID = "";
            })
            .catch(e => {
                console.error(e);
            })
        },
        openModal(id, name){
            this.transmittersRemoveModal = true;
            this.transmittersToRemove = name;
            this.transmittersToRemoveID = id;
        }
    },
    components: {
        jskSpinner
    },
    created(){
        this.fetchData(true);
        this.$parent.$on('refreshTransmitters', () => {
            this.fetchData(true)
        });

    }
}
</script>

<style lang="scss">
    
</style>